.v__page__loader__container {
	will-change: opacity;

	&-enter {
		opacity: 0;
	}

	&-enter-active {
		opacity: 1;
	}

	&-exit {
		opacity: 1;
	}

	&-exit-active {
		transition: $mid;
		transition-delay: 0.6s;
		opacity: 0;
	}
}

.v__animated__logo {
	opacity: 1 !important;
	z-index: 99999999;
	width: 12rem;

	.logo-element {
		animation: logoReposition;
		animation-duration: 0.5s;
		animation-delay: 0s;
		animation-timing-function: cubic-bezier(0.73, 0, 0.22, 0.99);
		animation-fill-mode: forwards;
		transform: translateX(calc(50% - 39.07px));
		.accent-wing {
			fill: $logo-bg;
		}
		.rest-wings {
			fill: $logo-accent;
		}
	}

	&--light {
		.logo-name {
			.characters {
				animation: lightLogoNameReveal;
			}
		}
	}
	&--dark {
		.logo-name {
			.characters {
				animation: darkLogoNameReveal;
			}
		}
	}

	.logo-name {
		.characters {
			animation-duration: 1s;
			animation-delay: 0.25s;
			animation-timing-function: linear;
			animation-fill-mode: forwards;
			stroke-dasharray: 350;
			stroke-dashoffset: 350;
			stroke-width: 1px;
			stroke: $primary-font;
			fill: transparent;
		}
	}
}

@keyframes logoReposition {
	0% {
		transform: translateX(calc(50% - 39.07px));
	}
	100% {
		transform: translateX(0);
	}
}

@keyframes lightLogoNameReveal {
	0% {
		fill: transparent;
		stroke-dashoffset: 350;
	}
	50% {
		fill: transparent;
	}
	100% {
		fill: #141c23;
		stroke-dashoffset: 0;
	}
}

@keyframes darkLogoNameReveal {
	0% {
		fill: transparent;
		stroke-dashoffset: 350;
	}
	50% {
		fill: transparent;
	}
	100% {
		fill: #dfefef;
		stroke-dashoffset: 0;
	}
}

.v__page__loader {
	position: fixed;
	z-index: 9999999;
	background: $bg-card;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

$offset: 187;
$duration: 1.4s;

@keyframes rotator {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(270deg);
	}
}

.v__spinner {
	animation: rotator $duration linear infinite;

	&__path {
		stroke-dasharray: $offset;
		stroke-dashoffset: 0;
		transform-origin: center;
		animation: dash $duration ease-in-out infinite, colors ($duration * 4) ease-in-out infinite;
	}

	&--single-color {
		margin-left: 0.625rem;
		.v__spinner__path {
			stroke: $success-text;
			animation: dash $duration ease-in-out infinite;
		}
	}
}

@keyframes colors {
	0% {
		stroke: $accent;
	}
	25% {
		stroke: $red;
	}
	50% {
		stroke: $green;
	}
	75% {
		stroke: $blue;
	}
	100% {
		stroke: $accent;
	}
}

@keyframes dash {
	0% {
		stroke-dashoffset: $offset;
	}
	50% {
		stroke-dashoffset: $offset/4;
		transform: rotate(135deg);
	}
	100% {
		stroke-dashoffset: $offset;
		transform: rotate(450deg);
	}
}

.v__line__loader {
	z-index: 999;
	opacity: 0;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 0;
	background: $bg-card;
	overflow: visible;

	&--visible {
		opacity: 1;
	}

	&--table-loader {
		top: 3.5rem;
	}

	.v__line__loader__line {
		z-index: 999999;
		display: block;
		position: relative;
		width: 1%;
		height: 0.25rem;
		border-radius: $radius;
		background-color: $accent;
		animation: cardLoader 1s ease-in-out alternate infinite;
	}
}

@keyframes cardLoader {
	0% {
		left: 0;
		width: 1%;
		background-color: $accent;
	}
	50% {
		width: 30%;
		background-color: $red;
	}
	100% {
		left: 100%;
		transform: translateX(-100%);
		width: 1%;
		background-color: $accent;
	}
}

/* Loading Animation */
@keyframes loading {
	100% {
		transform: translateX(100%);
	}
}
