@import "animations";
@import "loaders";
@import "buttons";
@import "cards";
@import "forms";
@import "calendars";
@import "tables";
@import "charts";
@import "partials";

@include scrollbar();
* {
	@include scrollbar();
}

a {
	color: $primary-font;
}

hr {
	border: 1px solid rgba($secondary-font, 0.1);
	border-radius: 5px;
	margin: 0;
	&.spaced {
		margin: 0.3125rem 0;
	}
}

//--------------------------
//----------LAYOUT----------
//--------------------------

.v__wrapper {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-wrap: wrap;
	width: 100%;
	height: 100%;
	//overflow: hidden;
	z-index: 1;
	position: relative;
}

//-----TOP BAR-----
.v__topbar__container {
	z-index: 999999;
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
	right: 0;
	background: $bg-card;
	color: $primary-font;
	box-shadow: 0 1px 0 0 rgba($secondary-font, 0.05);
	.v__topbar {
		height: 3.5rem;
		display: flex;
		align-content: center;
		justify-content: flex-start;
		flex-wrap: nowrap;
		.container {
			display: flex;
			justify-content: flex-end;
			padding: 0 1rem;
			@media (max-width: 1200px) {
				padding: 0 0.625rem;
			}
		}

		&__brand {
			position: relative;
			transition: $fast;
			flex: 0 0 17rem;
			position: relative;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			background: $sidebar-bg;
			@media (min-width: 1200px) {
				box-shadow: 1px 0 0 0 rgba($secondary-font, 0.05);
			}

			@mixin desktopBrandName() {
				transition: $fast;
				flex: 1;
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: flex-start;
				align-items: center;

				a {
					padding: 0 0 0 1rem;
					display: flex;
					align-items: center;
					&:focus {
						outline: none;
					}

					&:focus-visible {
						box-shadow: 0 0 0 1px $secondary-font;
					}

					svg {
						fill: $sidebar-primary-font;
						height: 1.5rem;
						width: 7rem;

						.accent-wing {
							fill: $logo-bg;
						}

						.rest-wings {
							fill: $logo-accent;
						}

						.characters {
							fill: $sidebar-primary-font;
						}
					}
				}
			}

			&__name {
				@include desktopBrandName();
			}

			&__trigger {
				margin: 0 0.5rem;

				i {
					color: $sidebar-primary-font;
				}

				&:active {
					background: rgba($sidebar-primary-font, 0.1);
				}

				&:focus-visible {
					box-shadow: 0 0 0 1px $secondary-font;
				}
			}

			.v__topbar__sidebar__overlap {
				z-index: 999;
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				width: 17rem;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				background: $sidebar-bg;
				box-shadow: 1px 0 0 0 rgba($secondary-font, 0.05);

				.v__topbar__brand__name {
					@include desktopBrandName();
				}
			}

			@media screen and (max-width: 1200px) {
				flex: 0 0 7rem;
				& > .v__topbar__brand__name {
					flex: 0 0 3.5rem;
					box-shadow: 1px 0 0 0 rgba($secondary-font, 0.05);

					a {
						width: 100%;
						justify-content: center;
						text-align: center;
						padding: 0;
						&:focus {
							outline: none;
						}
						&:focus-visible {
							box-shadow: 0 0 0 1px $secondary-font;
						}

						svg {
							width: 1.5rem;
						}
					}
				}
			}
		}

		&__user {
			transition: $fast;
			background: $bg;
			border-radius: $big-radius * 10;
			padding: 0 1rem 0 0.25rem;
			margin: auto 0.5rem auto 0;
			display: flex;
			align-items: center;
			height: 2.25rem;
			position: relative;
			cursor: pointer;

			&:focus {
				outline: none;
			}

			&:focus-visible {
				background-color: darken($bg, 2.5%);
			}

			&:hover {
				box-shadow: inset 0 0 0 0.125rem $accent-darker;
			}

			&__avatar {
				background: $bg;
				width: 1.75rem;
				height: 1.75rem;
				border-radius: 50%;
				object-fit: cover;
				font-size: 1.75rem;
				margin-right: 0.5rem;
				display: flex;
				justify-content: center;
				align-items: center;
				i {
					font-size: 1.25rem;
				}
			}

			&__name {
				font-size: 0.9rem;
				font-weight: 500;
				user-select: none;
			}

			@media (max-width: 800px) {
				padding: 0 0.25rem;
				margin: auto 0.3125rem auto 0;

				&__avatar {
					margin-right: 0;
				}
				&__name {
					display: none;
				}
			}
		}

		&__notifications {
			padding: 0 0.5rem;
			display: flex;
			align-items: center;
			position: relative;

			&__btn {
				position: relative;
				width: 2.25rem;
				height: 2.25rem;
				overflow: visible;

				.new {
					position: absolute;
					top: 0;
					left: 1.3125rem;
					min-width: 1.25rem;
					background: $danger;
					border-radius: $radius;
					z-index: 10;
					display: flex;
					align-items: center;
					justify-content: center;

					small {
						line-height: 1.4;
						display: block;
						padding: 0 0.25rem;
						color: $danger-text;
						font-weight: 500;
						white-space: nowrap;
						transform: translateY(0.05rem);
					}
				}
			}

			&__dropdown {
				min-width: 25rem;
				&__title {
					padding: 1rem;
					font-size: 1rem;
					font-weight: 600;
					line-height: 1;
					user-select: none;
				}

				@media (max-width: 600px) {
					position: fixed;
					top: 3.8rem;
					left: 0.625rem;
					right: 0.625rem;
					min-width: unset;
					width: calc(100% - 1.25rem);
				}
			}

			@media (max-width: 800px) {
				padding: 0 0.3125rem;
			}
		}

		&__extras {
			padding: 0;
			display: flex;
			align-items: center;
			position: relative;

			&__btn {
				position: relative;
				width: 2.25rem;
				height: 2.25rem;
				overflow: visible;
			}

			&__dropdown {
				.v__btn {
					margin-bottom: 0.3125rem;
					&:hover {
						.v__theme__switch {
							box-shadow: 0 0 0 1px $accent;
						}
					}
				}
				.v__theme__switch {
					pointer-events: none;
					margin: 0 0.5rem 0 -0.3125rem;
				}
			}

			@media (max-width: 800px) {
				&__name {
					opacity: 0;
					position: absolute;
					pointer-events: none;
				}
			}
		}
	}
}

//-----TOP BAR END-----

//-----SIDE-WRAPPER-----
.v__sidebar__wrapper {
	transition: $very-fast;
	position: relative;
	flex: 0 0 17rem;
	min-height: 100%;
	background: $sidebar-bg;
	will-change: flex;

	@media (max-width: 1200px) {
		transition: $very-fast;
		flex: 0 0 3.5rem;
	}

	@media (max-width: 800px) {
		transition: $very-fast;
		flex: 0 0 0;
	}

	&--minimized {
		transition: $very-fast;
		flex: 0 0 3.5rem;

		@media (max-width: 800px) {
			transition: $very-fast;
			flex: 0 0 0;
		}
	}

	.v__sidebar {
		will-change: width;
		transition: $very-fast;
		position: fixed;
		top: 3.5rem;
		left: 0;
		bottom: 0;
		z-index: 10001;
		width: 17rem;
		height: calc(100% - 3.5rem);
		display: flex;
		flex-direction: column;
		overflow: hidden;
		background: $sidebar-bg;
		box-shadow: 1px 0 0 0 rgba($secondary-font, 0.05);
		transform: unset;

		@media (max-width: 800px) {
			will-change: transform;
			transform: translateX(0);
		}

		&__scrollable {
			overflow: hidden;
			overflow-y: auto;
			height: 100%;
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			align-items: flex-start;
			justify-content: flex-start;

			&__profile {
				transition: $very-fast;
				background: $sidebar-bg-darker;
				width: 100%;

				&__user {
					transition: $very-fast;
					position: relative;
					overflow: hidden;
					display: flex;
					flex-wrap: nowrap;
					align-items: center;
					padding: 1rem 1rem 0.3125rem 0.5rem;
					cursor: pointer;

					&__avatar {
						position: relative;
						transition: $very-fast;
						flex: 0 0 3.5rem;
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: 2.5rem;

						&__status {
							position: absolute;
							top: 0;
							right: 0.5rem;
						}

						&__img {
							width: 2.5rem;
							height: 2.5rem;
							border-radius: 50%;
							object-fit: cover;
							background-color: $bg;
							font-size: 2.5rem;
						}
					}

					&__details {
						transition: $very-fast;
						position: relative;
						opacity: 1;
						pointer-events: auto;
						overflow: hidden;

						&__name {
							margin: 0;
							font-size: 0.9rem;
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
							color: $sidebar-primary-font;
						}

						&__role {
							margin: 0;
							font-size: 0.8rem;
							white-space: nowrap;
							color: $sidebar-secondary-font;
						}
					}
				}

				&__add {
					transition: $very-fast;
					display: flex;
					flex-wrap: wrap;
					padding: 0.3125rem 0;

					&__btn {
						transition: $very-fast;
						margin: 0.3125rem 1rem;
						white-space: nowrap;
						font-size: 0.9rem;

						i {
							transition: $very-fast;
							font-size: 1rem;
						}

						span {
							transition: $very-fast;
						}
					}
				}
			}

			&__nav {
				flex: 1;
				transition: $very-fast;
				padding-top: 0.625rem;

				&__item {
					transition: $very-fast;
					display: flex;
					align-items: center;
					justify-content: flex-start;
					flex-wrap: wrap;
					cursor: pointer;
					text-decoration: none;
					user-select: none;
					margin-bottom: 0.5rem;

					* {
						pointer-events: none;
						color: $sidebar-secondary-font;
					}

					a,
					.v__sidebar__scrollable__nav__item__link {
						pointer-events: auto;
						text-decoration: none;

						* {
							pointer-events: none;
						}
					}

					.v__sidebar__scrollable__nav__item__link {
						display: flex;
						justify-content: flex-start;
						align-items: center;
						padding: 0 0.5rem 0 0;
						width: 17rem;
						height: 2.5rem;

						&__icon__container {
							transition: $very-fast;
							flex: 0 0 3.5rem;
							display: flex;
							justify-content: flex-start;
							align-items: center;

							.v__sidebar__scrollable__nav__item__link__icon {
								transition: $very-fast;
								height: 2.5rem;
								display: flex;
								justify-content: center;
								align-items: center;
								margin-left: 0;
								width: 3rem;
								border-radius: 0 5rem 5rem 0;

								i {
									font-size: 1.25rem;
								}
							}
						}

						span {
							transition: $very-fast;
							flex: 1;
							opacity: 1;
						}

						&:hover {
							.v__sidebar__scrollable__nav__item__link__icon__container {
								box-shadow: inset 0.25rem 0 0 0 $accent;
							}
						}

						&:focus {
							outline: none;
						}

						&:focus-visible {
							// box-shadow: 0 0 0 1px $secondary-font;
							box-shadow: inset 0.25rem 0 0 0 $accent;
						}

						&--active {
							* {
								color: $sidebar-primary-font;
							}

							span {
								font-weight: 600;
							}

							.v__sidebar__scrollable__nav__item__link__icon__container {
								.v__sidebar__scrollable__nav__item__link__icon {
									background: $accent;

									i {
										color: $accent-font;
									}
								}
							}
						}
					}
				}
			}

			footer {
				flex: 0 0 5rem;
				transition: $very-fast;
				pointer-events: none;
				opacity: 1;
				width: 100%;
				white-space: nowrap;
				display: flex;
				justify-content: center;
				align-items: center;
				flex-wrap: nowrap;
				font-size: 0.8rem;
				font-weight: 300;
				color: rgba($sidebar-secondary-font, 0.8);
			}
		}

		&--inner-minimized {
			transition: $very-fast;
			width: 3.5rem;
			transform: unset;

			@media (max-width: 800px) {
				transition: $very-fast;
				width: 17rem;
				transform: translateX(-100%);
				box-shadow: 1px 0 0 0 rgba($secondary-font, 0.05);
			}

			.v__sidebar__scrollable {
				&__profile {
					transition: $very-fast;

					&__user {
						transition: $very-fast;
						padding: 1rem 0 0.3125rem 0;
						background: transparent;

						&__avatar {
							transition: $very-fast;
						}

						&__details {
							transition: $very-fast;
							opacity: 0;
							pointer-events: none;
						}
					}

					&__add {
						transition: $very-fast;

						&__btn {
							transition: $very-fast;
							margin: 0.3125rem 0.5rem;

							i {
								transition: $very-fast;
								padding: 0;
							}

							span {
								transition: $very-fast;
								position: absolute;
								opacity: 0;
								pointer-events: none;
							}
						}

						&__roles {
							transition: $very-fast;
							margin: 0.3125rem 0.5rem;
							opacity: 0;
							pointer-events: none;
						}
					}
				}

				&__nav {
					transition: $very-fast;

					&__item {
						&__link {
							.v__sidebar__scrollable__nav__item__link__icon__container {
								transition: $very-fast;

								.v__sidebar__scrollable__nav__item__link__icon {
									transition: $very-fast;
									margin-left: 0.5rem;
									width: 2.5rem;
									border-radius: 50%;
								}
							}

							span {
								transition: $very-fast;
								opacity: 0;
							}
						}

						&__extra-list {
							transition: $very-fast;
						}
					}
				}

				footer {
					transition: $very-fast;
					opacity: 0;
				}
			}
		}
	}

	.v__sidebar__overlay {
		transition: $fast;
		position: fixed;
		z-index: 1000;
		width: 100vw;
		height: 100%;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba($bg, 0.75);

		// @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
		//     backdrop-filter: blur(2px);
		// }
	}
}

.v__sidebar__swipe-area {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	width: 1rem;
	height: 100%;
	z-index: 99999;
	&--active {
		left: 16.5rem;
		width: 4rem;
	}
}

//-----SIDE-WRAPPER END-----

//-----CONTENT-WRAPPER-----
.v__content__wrapper {
	flex: 1;
	pointer-events: auto;
	width: 100%;
	min-height: calc(100% - 3.5rem);
	position: relative;
	display: block;
	background: $bg;
	color: $primary-font;
	margin-top: 3.5rem;

	.v__page {
		position: absolute;
		max-width: 100%;
		top: 0;
		left: 0;
		right: 0;

		//BREADCRUMBS AND PAGE TITLE
		&__header {
			display: block;
			background: $bg-card;
			box-shadow: 0 1px 0 0 rgba($secondary-font, 0.05);
			&__content {
				display: flex;
				justify-content: flex-end;
				align-items: center;
				flex-wrap: wrap;
				padding: 0.625rem 1rem;
				@media (max-width: 1200px) {
					padding: 0.625rem;
				}
				&__title {
					min-height: 2rem;
					display: flex;
					align-items: center;
					flex: 1;
					font-weight: 600;
					font-size: 1rem;
					color: $primary-font;
					margin-right: 0.625rem;
					&__user {
						display: flex;
						align-items: center;
						p {
							margin: 0;
							line-height: 1;
							padding-left: 0.625rem;
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
							span {
								margin-left: 0.625rem;
								opacity: 0.75;
								font-weight: 400;
								font-size: 0.9rem;
								overflow: hidden;
								white-space: nowrap;
								text-overflow: ellipsis;
								&.hoverable {
									cursor: pointer;
									&:hover {
										color: $accent-darker;
									}
								}
							}
							@media (max-width: 650px) {
								span {
									display: block;
									margin-left: 0;
									margin-top: 0.25rem;
									font-size: 0.8rem;
								}
							}
						}
						img,
						i {
							height: 2rem;
							width: 2rem;
							font-size: 2rem;
							border-radius: 50%;
							object-fit: cover;
						}
					}

					@media (max-width: 800px) {
						font-size: 0.9rem;
						font-weight: 500;
					}
				}

				.v__clock__container {
					user-select: none;
					position: relative;
					letter-spacing: 0.1rem;
					color: $secondary-font;
					background: $bg-darker;
					border-radius: $mini-radius;
					padding: 0.3125rem 0.625rem;
					line-height: 1;
					overflow: hidden;

					// .v__clock {

					// }
				}

				.v__table__extras {
					&__actions {
						display: flex;
						justify-content: flex-end;
						align-items: center;
						flex-wrap: wrap;

						& > *:last-child {
							margin-right: 0 !important;
						}
						& > * {
							margin-right: 0.625rem !important;
						}

						.v__form__group {
							min-width: 10rem;
						}

						// @media (max-width: 600px) {
						// 	flex: 0 0 100%;
						// 	justify-content: flex-start;
						// 	& > * {
						// 		flex: 0 1 auto;
						// 	}
						// }
					}
					&__views {
						flex: 0 0 100%;
						&__item {
							position: relative;
						}
					}
					&__filters {
						flex: 0 0 100%;
						display: block;
						padding-top: 0.625rem;
						margin-bottom: -0.625rem;
					}
				}
			}
		}

		&__content {
			display: block;
			&__content {
				display: block;
				padding: 1rem 1rem 4rem 1rem;
				@media (max-width: 1200px) {
					padding: 0.625rem 0.625rem 4rem 0.625rem;
				}
			}
		}

		//404 PAGE
		&__missing {
			height: calc(100vh - 5.75rem);
			min-height: 40rem;
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: nowrap;
			position: relative;
			overflow: hidden;
			user-select: none;

			&__graphic {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				opacity: 0.05;
				display: flex;
				flex-wrap: nowrap;
				align-items: center;

				&__code {
					font-size: 12rem;
					font-weight: 800;
					color: $primary-font;
				}

				& > i {
					font-size: 13rem;
					padding: 0 0 0 1.5rem;
				}

				@media (max-width: 900px) {
					transform: translate(-50%, -50%) scale(0.5);
				}
			}

			&__description {
				font-size: 3rem;
				color: rgba($secondary-font, 0.9);
				font-weight: 600;
				@media (max-width: 900px) {
					font-size: 2rem;
				}
			}
		}
	}
}

//-----CONTENT-WRAPPER END-----

//-----RIGHT PANEL-----
.v__right__panel__wrapper {
	will-change: transform, opacity;

	&-enter {
		opacity: 0;

		.v__right-panel {
			transform: translateX(100%);
		}
	}

	&-enter-active {
		transition: $fast;
		opacity: 1;

		.v__right-panel {
			transition: $mid;
			transform: translateX(0);
		}
	}

	&-exit {
		opacity: 1;

		.v__right-panel {
			transform: translateX(0);
		}
	}

	&-exit-active {
		transition: $mid;
		opacity: 0;

		.v__right-panel {
			transition: $fast;
			transform: translateX(100%);
		}
	}
}

.v__right-panel__overlay {
	z-index: 9999999;
	position: fixed;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	pointer-events: auto;
	background: transparent;
	background: rgba($bg, 0.75);

	// @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
	//     backdrop-filter: blur(2px);
	// }

	.v__right-panel {
		position: fixed;
		height: 100%;
		overflow: hidden;
		overflow-y: auto;
		overscroll-behavior: contain;
		top: 0;
		right: 0;
		max-width: 25rem;
		width: 100%;
		background: $bg-card;
		color: $primary-font;
		border-collapse: collapse;
		padding: 0 0 2rem 0;
		box-shadow: $big-shadow;
		@media (max-width: 500px) {
			max-width: 100%;
		}

		&--darker {
			background: $bg;
		}
	}

	.v__right-panel__header {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding: 0 1rem;
		height: 3.5rem;
		background: $bg-card;

		&__heading {
			flex: 1;
			font-weight: 600;
		}

		&__btn {
			width: 2rem;
			height: 2rem;
		}
	}
}

//-----RIGHT PANEL END-----

//------------------------------
//----------LAYOUT END----------
//------------------------------

//-------------------------
//----------PAGES----------
//-------------------------

//-----DASHBOARD LAYOUT-----
.v__dashboard__layout {
	position: relative;
	display: grid;
	grid-template-areas:
		"delivery-map               report-btn                  staff-box"
		"delivery-map               calendar                    staff-box"
		"performance-chart          performance-chart           performance-chart";
	grid-template-columns: 1fr 1fr 20rem;
	gap: 0 1rem;

	.v__dashboard__report__btn {
		grid-area: report-btn;
		border-radius: $radius;
	}

	.v__report__calendar {
		grid-area: calendar;
	}

	.v__performance__chart {
		grid-area: performance-chart;
		min-height: 24rem;
	}

	.v__delivery__map {
		grid-area: delivery-map;
		// @include mapDefaults();

		.v__map {
			width: 100%;
			height: 28.575rem;
		}
	}

	.v__invoices {
		grid-area: invoices-card;
	}

	.v__staff {
		grid-area: staff-box;

		.v__staff__container {
			height: 28.575rem;
			@include staffPanels();
		}
	}

	@media (max-width: 1400px) {
		grid-template-areas:
			"staff-box            report-btn"
			"staff-box            calendar"
			"performance-chart    performance-chart"
			"delivery-map         delivery-map";
		grid-template-columns: 1fr 1fr;

		.v__staff {
			.v__staff__container {
				height: 28rem;
			}
		}
	}

	@media (max-width: 1200px) {
		grid-template-areas:
			"report-btn"
			"calendar"
			"performance-chart"
			"staff-box"
			"delivery-map";
		grid-template-columns: 1fr;
		gap: 0 0.625rem;
	}

	&--no-report-btn {
		grid-template-areas:
			"delivery-map               calendar                    staff-box"
			"performance-chart          performance-chart           performance-chart";

		.v__delivery__map {
			.v__map {
				width: 100%;
				height: 24.575rem;
			}
		}

		.v__staff {
			.v__staff__container {
				height: 24.575rem;
			}
		}

		@media (max-width: 1400px) {
			grid-template-areas:
				"staff-box            calendar"
				"performance-chart    performance-chart"
				"delivery-map         delivery-map";
			grid-template-columns: 1fr 1fr;
		}

		@media (max-width: 1200px) {
			grid-template-areas:
				"report-btn"
				"calendar"
				"performance-chart"
				"staff-box"
				"delivery-map";
			grid-template-columns: 1fr;
			gap: 0 0.625rem;
		}
	}

	&--simplified {
		grid-template-areas:
			"delivery-map               report-btn"
			"delivery-map               calendar"
			"performance-chart          performance-chart";
		grid-template-columns: 1fr 1fr;
		gap: 0 1rem;

		@media (max-width: 1200px) {
			grid-template-areas:
				"report-btn"
				"calendar"
				"performance-chart"
				"delivery-map";
			grid-template-columns: 1fr;
			gap: 0 0.625rem;
		}
	}
}

//-----DASHBOARD LAYOUT END-----

//-----USER VIEW LAYOUT-----

//-----USER VIEW LAYOUT END-----

//-----SETTINGS LAYOUT-----
.v__settings__item {
	margin-bottom: 0.625rem;

	&__desc {
		&__label {
			display: block;
			text-align: right;
			font-size: 0.9rem;
			margin: 0 0 0.3125rem 0;
		}

		&__hint {
			display: block;
			text-align: right;
			font-size: 0.8rem;
			opacity: 0.75;
			line-height: 1.2;
			width: 75%;
			margin-left: auto;
		}
	}

	&:last-child {
		margin-bottom: 0;
	}

	@media (max-width: 800px) {
		margin-bottom: 0.3125rem;
		&__desc {
			&__label {
				text-align: left;
				margin-left: 0.625rem;
			}

			&__hint {
				text-align: left;
				width: 100%;
				margin-left: 0.625rem;
				margin-top: -0.3125rem;
				margin-bottom: 0.3125rem;
			}
		}
	}
}

//-----SETTINGS LAYOUT END-----

//-----------------------------
//----------PAGES END----------
//-----------------------------

@import "helpers";

@mixin reportTablePrint() {
	.v__page__content__content {
		padding: 0;
	}

	.v__card,
	.v__modal {
		margin: 0;
	}

	.print-only-table {
		display: table;
		width: 100%;
		border-collapse: collapse;
		border-spacing: 0;
		border: 1px solid black;
		margin-bottom: 0.625rem;
		font-family: "Roboto", sans-serif;
		font-size: 0.85rem;
		td,
		th {
			text-align: left;
			border: 1px solid black;
			padding: 0 0.125rem;
		}
		th {
			font-weight: 500;
			background: lightgray !important;
		}
		td {
			text-transform: capitalize;
		}
	}

	.v__table__pagination {
		flex-wrap: nowrap;

		&__page__results {
			flex: 1;
			display: block;
			padding: 0;
			font-size: 0.9rem;

			.v__form__group {
				display: none;
			}

			.v__table__pagination__page__results__current {
				display: inline-block !important;
			}

			& > span,
			& > label,
			& > strong {
				padding-right: 0.3125rem;
			}
		}

		&__pages {
			display: block;
			& > span {
				font-size: 0.9rem;
				padding-right: 0;

				& > span,
				& > strong {
					padding-right: 0.3125rem;
				}
			}

			&__group {
				display: none;
			}
		}
	}

	.v__table__container {
		box-shadow: none;
		border-radius: 0;
		margin: 0;
	}

	.v__report__table {
		border: 1px solid black;
		border-collapse: collapse;
		border-spacing: 0;
		background: white;

		&__extras {
			display: none;
		}

		td,
		th {
			color: black !important;
			background: white !important;
			box-shadow: none;
			border: 1px solid black;
			padding: 0.25rem 0.125rem;
			* {
				color: black !important;
			}
		}
		th {
			position: unset;

			&.v__report__table__header__row__cell--sticky {
				position: unset;
			}
			&.v__report__table__header__row__cell--asc,
			&.v__report__table__header__row__cell--desc,
			&.v__report__table__header__row__cell--filtered {
				position: relative;
			}
		}
		td {
			height: auto;
			&.v__report__table__body__row__cell--clickable-redirect,
			&.v__report__table__body__row__cell--clickable-action,
			&.v__report__table__body__row__cell--clickable {
				white-space: unset;
				text-overflow: unset;
				overflow: unset;
			}
			&.v__report__table__body__row__cell--sticky {
				position: unset;
			}
			&:last-child {
				box-shadow: none;
			}
		}

		&__body {
			&--with-avg {
				tr:nth-child(1),
				tr:nth-child(2) {
					td {
						background: $warning !important;
						box-shadow: none;
						&:first-child {
							box-shadow: none;
						}
						.cell__pigment {
							border: 0.0625rem solid black;
						}
					}
				}
			}

			.v__table__body__row--empty {
				& > td {
					border-radius: 0;
					svg {
						.backboard {
							fill: black;
							&-paper {
								fill: white;
							}
						}
						.clip,
						.clip-round {
							fill: gray;
						}
					}
				}
			}
		}
	}
}

@media screen {
	.screen-hide {
		display: none !important;
	}
}

@media print {
	#root {
		height: 0;
		overflow-y: visible;
	}
	.print-title {
		font-size: 1.5rem;
		font-weight: 400;
		margin: 0.25rem 0;
	}
	.v__to-top {
		display: none;
	}
	.container {
		max-width: unset;
	}
	.v__topbar__container {
		display: none;
	}
	.v__sidebar__wrapper {
		display: none;
	}
	.v__content__wrapper {
		margin-top: 0;
		color: black !important;
		background: white !important;
		.v__page {
			position: relative;

			&__header {
				display: none;
			}

			.v__reports__calendar__navigation__container {
				display: none;
			}

			@include reportTablePrint();

			.v__tab__card {
				background: white;
				color: black;
				box-shadow: none;
				border-radius: 0;
				&__header {
					display: none;
				}
				.v__tab__card__panel {
					padding: 0;
				}
			}

			.profile__print {
				display: flex;
				align-items: flex-start;
				border: 2px solid black;
				&__user {
					flex: 1;
					display: flex;
					flex-wrap: wrap;
					&__division {
						flex: 0 0 auto;
						font-size: 1.25rem;
						padding: 0 1rem;
						border-bottom: 1px solid black;
						display: grid;
						place-items: center;
					}
					&__info {
						flex: 1;
						border-bottom: 1px solid black;
						h2 {
							font-weight: 400;
							margin-bottom: 0;
						}
					}
					.user__connections,
					.staff__members {
						flex: 0 0 100%;
						display: flex;
						flex-wrap: wrap;
						& > * {
							margin: 0.5rem 1rem;
						}
					}
				}
				&__divisions {
					flex: 0 0 auto;
					table {
						width: auto;
						border: none;
						border-left: 1px solid black;
						td,
						th {
							border: none;
						}
						th {
							border-bottom: 1px solid black;
							text-align: left;
						}
						th:first-child,
						td:first-child {
							border-right: 1px solid black;
						}
						td span {
							margin-left: 0.5rem;
							text-transform: capitalize;
						}
					}
				}
			}

			.print-main-stats {
				margin-top: 0.625rem;
				td,
				th {
					font-size: 1rem;
				}
				i {
					font-size: 1rem;
					transform: translate(0, 0.125rem);
					padding-right: 0.25rem;
				}
			}
			.print-other-stats {
				margin-top: 0.625rem;
				i {
					font-size: 1rem;
					transform: translate(0, 0.125rem);
				}
			}
			.print-view-report-table {
				margin-top: 0.625rem;
			}
			.print-performance {
				margin-top: 1rem;
			}

			.apexcharts-canvas {
				.apexcharts-svg {
					text {
						fill: black;
					}

					.apexcharts-legend {
						.apexcharts-legend-series {
							.apexcharts-legend-text {
								color: black !important;
							}
						}
					}

					.apexcharts-inner {
						.apexcharts-xaxis {
							.apexcharts-xaxis-texts-g {
								text {
									fill: black;
								}
							}
						}
						.apexcharts-grid {
							line {
								stroke: rgba(0, 0, 0, 0.1);
							}
						}
					}
				}
			}
		}
	}
	.v__modal__overlay {
		background: none;
		position: relative;
		z-index: 9999;
		&__inner {
			padding: 0;
			@include reportTablePrint();
			.v__card,
			.v__modal {
				width: 100%;
				background: none;
				color: unset;
				border: none;
				max-width: unset !important;
				&__header {
					display: none;
				}
				&__body {
					color: unset;
					padding: 0;
				}
			}
		}
	}

	.print-hide {
		display: none !important;
	}
}
