//-----GRID-----
$columns: 12;
$width: 100% / $columns;

.v__grid {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-wrap: wrap;
	flex-direction: row;
	margin-left: -0.625rem;
	margin-right: -0.625rem;
	&--no-margin {
		margin-left: 0;
		margin-right: 0;
	}
	&--unwrap {
		flex-wrap: nowrap;
	}
	.v__grid__item {
		padding-left: 0.625rem;
		padding-right: 0.625rem;
		flex: 1;
		@for $i from 1 through $columns {
			&--col-#{$i} {
				flex: 0 0 $width * $i;
			}
			&--col-hide {
				display: none;
			}
			&--col-md-#{$i} {
				@media all and (max-width: 1200px) {
					flex: 0 0 $width * $i;
				}
			}
			&--col-md-hide {
				@media all and (max-width: 1200px) {
					display: none;
				}
			}
			&--col-sm-#{$i} {
				@media all and (max-width: 800px) {
					flex: 0 0 $width * $i;
				}
			}
			&--col-sm-hide {
				@media all and (max-width: 800px) {
					display: none;
				}
			}
		}
	}
}

//-----GRID END-----

.container {
	max-width: 102.9375rem;
	min-width: 0;
	width: 100%;
	margin: 0 auto;
	position: relative;
	&--fluid {
		max-width: unset;
	}
}

.text-center {
	text-align: center !important;
}

.text-accent {
	color: $accent !important;
}

.text-accent-darker {
	color: $accent-darker !important;
}

.text-red {
	color: $red !important;
}

.text-green {
	color: $green !important;
}

.text-gold {
	color: $gold !important;
}

.text-bronze {
	color: $bronze !important;
}

.disabled {
	pointer-events: none !important;
	opacity: 0.7 !important;
}

.sub-text {
	opacity: 0.7 !important;
	&-hoverable:hover {
		color: $accent-darker;
		cursor: pointer;
	}
}

.faded {
	opacity: 0.25;
}

.w-100 {
	width: 100% !important;
}

.hide {
	display: none !important;
}

.pad-xy-2 {
	padding: 2rem;
}

.pad-b-1 {
	padding-bottom: 1rem;
	@media (max-width: 1200px) {
		padding-bottom: 0.625rem;
	}
}

.no-padding {
	padding: 0 !important;
}

.no-margin {
	margin: 0 !important;
}

.no-overflow {
	overflow: hidden !important;
}

.no-pointer-events {
	pointer-events: none !important;
}

.success-darken {
	background: darken($success, 5%);
}

.info-darken {
	background: darken($accent, 5%);
}

.emoji {
	display: inline-block;
	width: auto;
	height: 1.4em;
	vertical-align: -0.2em;
}

.v__hints__grid {
	& > .v__grid__item {
		display: flex;
		.v__hints__title {
			margin: 0 0 1rem 0;
			font-weight: 400;
			font-size: 1.25rem;
		}
		.v__key {
			&__combo {
				flex: 0 0 15rem;
				padding: 0 0.625rem 1rem 0.625rem;
				color: $accent-darker;
			}
			&__action {
				flex: 1;
				padding: 0 0.625rem 1rem 0.625rem;
				small {
					opacity: 0.75;
					display: block;
					padding: 0.5rem 0;
				}
			}
		}
		@media (max-width: 600px) {
			flex-wrap: wrap;
			.v__hints__title {
				margin: 1rem 0;
			}
			.v__key {
				&__combo {
					flex: 0 0 100%;
					padding: 0.5rem;
				}
				&__action {
					flex: 0 0 100%;
					padding: 0.5rem;
					small {
						padding: 0.5rem 0;
					}
				}
			}
		}
	}
}

$icons: "accent" $accent $accent-font, "success" $success $success-text, "warning" $warning $warning-text, "danger" $danger $danger-text;

@each $name, $color, $font in $icons {
	.icon-#{$name} {
		background: $color;
		color: $font;
	}
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
	select,
	textarea,
	input {
		font-size: 16px !important;
	}
}
