@mixin scrollbar($trackBg: $bg-card, $thumbBg: $primary-font, $size: 0.75rem, $thumbRadius: $mini-radius) {
	@media (pointer: fine) {
		&::-webkit-scrollbar {
			width: $size;
			height: $size;
		}
		&::-webkit-scrollbar-track {
			background: $trackBg;
		}
		&::-webkit-scrollbar-thumb {
			background: rgba($thumbBg, 0.2);
			border-radius: $thumbRadius;
			border: $size * 0.225 solid $trackBg;

			&:vertical:hover {
				background: rgba($thumbBg, 0.4);
			}

			&:horizontal:hover {
				background: rgba($thumbBg, 0.4);
			}
		}
		&::-webkit-scrollbar-corner {
			background: $trackBg;
		}
	}
	& {
		scrollbar-width: thin;
		scrollbar-color: rgba($thumbBg, 0.2) $trackBg;
	}
}

@mixin spongy($amount) {
	transition: $very-fast;
	&:active {
		transition: $very-fast;
		transform: scale($amount);
	}
}

@mixin card() {
	background: $bg-card;
	color: $primary-font;
	border-radius: $mini-radius;
	padding: 0;
	width: 100%;
	overflow: hidden;
	margin: 0 0 1rem 0;
	@media (max-width: 1200px) {
		margin: 0 0 0.625rem 0;
	}
}

@mixin badge($background, $fontColor) {
	background: $background;
	color: $fontColor;
	font-size: 0.7rem;
	font-weight: 500;
	border-radius: $radius;
	padding: 0.125rem 0.5rem;
	width: auto;
	text-align: center;
	white-space: nowrap;
	user-select: none;
	display: flex;
	justify-content: center;
	align-items: center;
	i {
		font-size: 0.9rem;
		padding: 0 0.5rem 0 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	&.v__badge__clickable {
		cursor: pointer;
	}
}

@mixin badgeSize {
	&.v__badge__md {
		font-size: 0.8rem;
		i {
			font-size: 1rem;
		}
	}

	&.v__badge__lg {
		font-size: 0.9rem;
		i {
			font-size: 1.1rem;
		}
	}
}

@mixin button($background, $fontColor) {
	position: relative;
	height: 2.375rem;
	padding: 0 0.625rem;
	margin: 0;
	border: none;
	background: $background;
	border-radius: $mini-radius;
	width: auto;
	cursor: pointer;
	color: $fontColor;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 0.8rem;
	font-weight: 500;
	user-select: none;
	overflow: hidden;
	line-height: 1;

	i {
		font-size: 1.15rem;
	}

	&:focus {
		outline: none;
	}

	&:focus-visible {
		box-shadow: 0 0 0 1px $secondary-font;
	}

	& > * {
		user-select: none;
		text-decoration: none;
		cursor: pointer;
		color: $fontColor;
	}
}

@mixin tooltip() {
	z-index: 999999999999;
	position: absolute;
	background: $bg-card;
	color: $primary-font;
	box-shadow: $mini-shadow;
	padding: 0.625rem;
	border-radius: $mini-radius;
	text-transform: none;
	border: 1px solid rgba($secondary-font, 0.1);
	min-width: 15rem;
	max-width: 20rem;
	pointer-events: none;
	font-weight: 400;
	font-size: 0.8rem;
	top: 3.625rem;
}

@mixin selectDefaults($size, $background) {
	.v__select__control {
		height: $size;
		background-color: $background;
		border: none;
		border-radius: $mini-radius;
		flex-wrap: nowrap;
		min-height: unset;
		min-width: 5rem;

		.v__select__value-container {
			padding: 0 0.625rem;

			.v__select__placeholder {
				color: $secondary-font;
				font-size: 0.9rem;
				margin-right: 0;
				margin-left: 0;
				white-space: nowrap;
			}

			.v__select__input {
				color: $primary-font !important;
				font-size: 0.9rem;
				margin-left: -2px;
			}

			.v__select__single-value {
				color: $primary-font;
				font-size: 0.9rem;
				margin-left: 0;
				margin-right: 0;
			}
		}

		.v__select__indicators {
			.v__select__indicator {
				cursor: pointer;
				padding: 0 0.5rem;
			}

			.v__select__indicator-separator {
				background-color: rgba($secondary-font, 0.2);
			}

			.v__select__loading-indicator {
				color: $secondary-font;
			}

			.v__select__clear-indicator {
				color: $secondary-font;
			}

			.v__select__dropdown-indicator {
				transition: $fast;
				transform: rotateX(0deg);
				color: $secondary-font;
			}
		}

		&--is-focused {
			box-shadow: none;
		}

		&--menu-is-open {
			box-shadow: 0 0 0 1px $accent;

			.v__select__indicators {
				.v__select__dropdown-indicator {
					transition: $fast;
					transform: rotateX(180deg);
					color: $accent;
				}
			}
		}
	}
}

@mixin selectGroupSeparatorDefaults() {
	&__group {
		padding: 0;

		&-heading {
			margin: 0;
		}

		&__separator {
			font-size: 0.9rem;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			padding: 0.625rem 0;
			border-bottom: 1px solid rgba($secondary-font, 0.2);

			&__label {
				flex: 1;
				font-weight: 600;
				color: $accent-darker;
			}

			&__results {
				padding: 0 0.625rem;
				border-radius: $mini-radius;
			}
		}
	}
}

@mixin virtualizedSelectOption() {
	.fast-option {
		background: $bg-card-darker;
		color: $primary-font;
		cursor: pointer;
		font-size: 0.9rem;
		display: flex;
		align-items: center;
		padding: 0 0 0 0.625rem;
		line-height: 1 !important;
		* {
			line-height: 1 !important;
		}

		i {
			font-size: 0.9rem;
			padding-right: 0.3125rem;
		}

		span {
			margin-right: 0.3125rem;
		}

		&:hover {
			background: $bg-darker;
		}

		&-selected {
			background: rgba($bg-darker, 0.5);
			&.fast-option-focused {
				background: rgba($bg-darker, 0.75);
			}
		}

		&-focused {
			background: rgba($bg-darker, 0.5);
		}
	}
}

@mixin selectMenuDefaults() {
	&__menu {
		min-width: 5rem;
		z-index: 10000;
		background: $bg-card-darker;
		box-shadow: 0 0 0 1px $accent, $mini-shadow;

		* {
			overscroll-behavior: contain;
		}

		.v__select__menu-list {
			padding: 0;
			border-radius: $mini-radius;
			overflow-x: hidden;
			overflow-y: auto;

			.v__select__menu-notice {
				color: $secondary-font;
			}

			.v__select__option {
				background: $bg-card-darker;
				color: $primary-font;
				cursor: pointer;
				font-size: 0.9rem;
				display: flex;
				align-items: center;

				i {
					font-size: 0.9rem;
					padding-right: 0.3125rem;
				}

				span {
					margin-right: 0.3125rem;
				}

				&:hover {
					background: $bg-darker;
				}

				&--is-selected {
					background: rgba($bg-darker, 0.5);
					&.v__select__option--is-focused {
						background: rgba($bg-darker, 0.75);
					}
				}

				&--is-focused {
					background: rgba($bg-darker, 0.5);
				}

				&--is-disabled {
					opacity: 0.75;
					color: $accent-darker;
				}
			}
		}

		.ReactVirtualized__Grid {
			padding: 0;
			border-radius: $mini-radius;

			&__innerScrollContainer {
				.flat-virtualized-item {
					@include virtualizedSelectOption();
				}

				.grouped-virtualized-list-item {
					.v__select__group__separator {
						font-size: 0.9rem;
						display: flex;
						justify-content: flex-start;
						align-items: center;
						padding: 0;
						margin: 0 0.625rem;
						border-bottom: 1px solid rgba($secondary-font, 0.2);

						&__label {
							flex: 1;
							font-weight: 600;
							color: $accent-darker;
							text-transform: uppercase;
						}

						&__results {
							padding: 0 0.625rem;
							border-radius: $mini-radius;
							color: rgba($secondary-font, 0.75);
						}
					}

					@include virtualizedSelectOption();
				}
			}
		}
	}
}

@mixin inputDefaults($size) {
	background: $bg-darker;
	padding: 0 0.625rem 0 0;
	text-indent: 0.625rem;
	border: none;
	width: 100%;
	color: $primary-font;
	font-size: 0.9rem;
	border-radius: $mini-radius;
	height: $size;

	&:focus {
		outline: none;
		box-shadow: 0 0 0 1px $accent;
	}

	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus {
		border: none;
		box-shadow: 0 0 0px 1000px $bg inset;
		-webkit-text-fill-color: $primary-font;
		-webkit-box-shadow: 0 0 0px 1000px $bg inset;
		transition: background-color 5000s ease-in-out 0s;
	}

	&:-webkit-autofill:focus {
		box-shadow: 0 0 0 1px $accent;
	}
}

@mixin switchGroupDefaults($size, $radius, $dark: true) {
	transition: $mid;
	display: flex;
	align-items: center;
	position: relative;
	border-radius: $radius;
	width: 100%;
	overflow: hidden;
	height: $size;
	// padding: 0 0.25rem;
	padding: 0;
	// @if ($dark) {
	// 	background: $bg;
	// 	@media (pointer: fine) {
	// 		&:hover {
	// 			box-shadow: inset 0 0 0 0.125rem $accent;
	// 		}
	// 	}
	// } @else {
	// 	background: $bg-card;
	// 	@media (pointer: fine) {
	// 		&:hover {
	// 			box-shadow: inset 0 0 0 0.125rem $accent;
	// 		}
	// 	}
	// }

	.v__switch__group__selector {
		display: none;
		// transition: $mid;
		// position: absolute;
		// height: 100%;
		// bottom: 0;
		// top: 0;
		// background: $accent;
		// border-radius: $radius;
		// z-index: 1;
	}

	.v__switch__group__item {
		z-index: 2;
		flex: 1 1 auto;
		user-select: none;
		cursor: pointer;
		background: transparent;
		margin-right: 0.125rem;
		position: relative;
		background: $bg-darker;
		// &::after {
		// 	content: "";
		// 	position: absolute;
		// 	top: 0.25rem;
		// 	right: calc(-0.21875rem);
		// 	width: 1px;
		// 	height: calc(100% - 0.5rem);
		// 	background: rgba($secondary-font, 0.2);
		// }

		&:last-child {
			margin-right: 0;
			// &::after {
			// 	display: none;
			// }
		}

		&:focus {
			outline: none;
		}

		&:focus-visible {
			background-color: $bg;
		}

		input[type="radio"] {
			position: absolute;
			opacity: 0;
			pointer-events: none;
		}

		&__label {
			transition: $mid;
			font-size: 0.8rem;
			font-weight: 400;
			line-height: 1;
			height: $size;
			padding: 0 $size / 2;
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
			// border-radius: $radius;
			white-space: nowrap;

			&:focus {
				outline: none;
			}

			&:focus-visible {
				background-color: $bg;
				box-shadow: inset 0 0 0 1px $accent;
			}

			@media (pointer: fine) {
				&:hover {
					background: darken($bg, 2.5%);
					// box-shadow: inset 0 0 0 1px $accent;
				}
			}

			i {
				font-size: 0.8rem;
				line-height: 1;
				padding-right: 0.5rem;
			}

			&__secondary {
				transition: $mid;
				padding: 0.075rem 0.3125rem;
				margin: 0 0 0 0.3125rem;
				border-radius: $mini-radius;
				font-size: 0.6rem;
			}
		}

		&--active {
			.v__switch__group__item__label {
				transition: $mid;
				color: $accent-font;
				background: $accent;

				&__secondary {
					transition: $mid;
					box-shadow: none;
					background: $accent-font;
					color: $accent-darker;
				}

				@media (pointer: fine) {
					&:hover {
						background: $accent-darker;
						// box-shadow: none;
					}
				}
			}
		}
	}
}

@mixin switchDefaults($height, $width) {
	padding: 0;
	margin: 0;
	display: inline-block;

	.v__switch__input {
		position: absolute;
		opacity: 0;
		pointer-events: none;

		&:checked + .v__switch__label {
			.v__switch__rail {
				transition: $fast;
				background: $accent;

				.v__switch__slider {
					transition: $fast;
					background: $accent-font;
					left: $width - ($height - 0.25rem);
				}
			}
		}

		&:focus + .v__switch__label {
			.v__switch__rail {
				box-shadow: 0 0 0 1px $accent;
			}
		}
	}

	.v__switch__label {
		cursor: pointer;
		margin: 0;
		padding: 0;

		&:hover {
			outline: none;

			.v__switch__rail {
				box-shadow: 0 0 0 1px $accent;
			}
		}

		.v__switch__rail {
			transition: $fast;
			position: relative;
			height: $height;
			width: $width;
			background: $bg-darker;
			border-radius: $big-radius;

			.v__switch__slider {
				transition: $fast;
				position: absolute;
				width: $height - 0.5rem;
				height: $height - 0.5rem;
				top: 0.25rem;
				left: 0.25rem;
				background: $bg-card-darker;
				border-radius: $big-radius;
			}
		}
	}
}

@mixin calendar() {
	width: 100%;
	border: none;
	background: $bg-card;
	font-family: $font-family;

	button {
		border: none;
		padding: 0;
		background: transparent;
		cursor: pointer;

		&:focus {
			outline: none;
		}

		&:focus-visible {
			box-shadow: 0 0 0 1px $secondary-font;
		}
	}

	.react-calendar__navigation {
		height: unset;
		margin-bottom: 0.625rem;
		align-items: center;
		padding: 0.3125rem 0;

		&__arrow {
			background: none;
			min-width: unset;
			padding: 0 0.25rem;

			& > div {
				width: 2.375rem;

				&.unset-width {
					width: auto;
				}
			}

			&:hover {
				background: none;
			}
		}

		&__label {
			background: none;
			min-width: unset;
			padding: 0 0.25rem;
			color: $primary-font;
			font-size: 1rem;
			cursor: auto;
		}
	}

	.react-calendar__viewContainer {
		.react-calendar__month-view {
			background: none;
			.react-calendar__month-view__weekNumbers {
				height: 17.25rem;
				flex-basis: 2rem !important;
				user-select: none;
				box-shadow: 1px 0 0 0 $dt-border;

				.react-calendar__tile {
					padding: 0;
					display: flex;
					justify-content: flex-start;
					align-items: center;
					position: relative;
					font-size: 0.8rem;
					font-weight: 600;
					margin: 0;
					border: 0;

					span {
						font-family: "Roboto", sans-serif;
						color: $accent-darker;
						padding: 0.3125rem 0;

						&:hover {
							&:after {
								opacity: 1;
							}
						}

						&:after {
							pointer-events: none;
							opacity: 0;
							z-index: 99999;
							content: "Week";
							position: absolute;
							top: 50%;
							transform: translateY(-50%);
							left: 1.5rem;
							background: $bg;
							border: 1px solid rgba($secondary-font, 0.1);
							border-radius: $mini-radius;
							padding: 0.3125rem 0.625rem;
							color: $secondary-font;
						}
					}
				}
			}

			.react-calendar__month-view__weekdays {
				margin-bottom: 0.625rem;

				&__weekday {
					text-align: center;
					padding: 0.25rem;
					overflow: visible !important;

					abbr {
						text-decoration: none;
						text-transform: uppercase;
						padding: 0.125rem 0;
						border-bottom: 1px solid $secondary-font;
						border-radius: 1px;
						font-size: 0.7rem;
						font-weight: 600;
						user-select: none;
					}
				}
			}

			.react-calendar__month-view__days {
				width: 100%;

				.react-calendar__tile--now,
				.react-calendar__tile--active,
				.react-calendar__tile--rangeStart,
				.react-calendar__tile--rangeEnd,
				.react-calendar__tile--rangeBothEnds {
					background: none;

					abbr {
						color: $accent-font;
					}

					.circle {
						background: $accent;
					}

					&:hover {
						abbr {
							color: $accent-font;
						}

						.circle {
							background: darken($accent, 2.5%);
						}
					}

					&.react-calendar__month-view__days__day--file {
						.circle {
							background: linear-gradient(135deg, $danger 22.5%, $accent 25%, $accent 75%, $danger 77.5%);
						}

						&:hover {
							.circle {
								background: linear-gradient(
									135deg,
									darken($danger, 2.5%) 22.5%,
									darken($accent, 2.5%) 25%,
									darken($accent, 2.5%) 75%,
									darken($danger, 2.5%) 77.5%
								);
							}
						}
					}

					&.react-calendar__month-view__days__day--depo-file {
						.circle {
							background: linear-gradient(135deg, $warning 22.5%, $accent 25%, $accent 75%, $warning 77.5%);
						}

						&:hover {
							.circle {
								background: linear-gradient(
									135deg,
									darken($warning, 2.5%) 22.5%,
									darken($accent, 2.5%) 25%,
									darken($accent, 2.5%) 75%,
									darken($warning, 2.5%) 77.5%
								);
							}
						}
					}
				}

				.react-calendar__tile:enabled:hover,
				.react-calendar__tile:enabled:focus {
					background: none;
				}

				.react-calendar__tile:enabled:focus-visible {
					background: $bg;
				}

				&__day {
					padding: 0;
					position: relative;
					height: 2.875rem;
					border-radius: 10rem;
					color: $primary-font;
					flex-basis: 13% !important;
					flex-grow: 1;

					abbr {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						color: $primary-font;
						font-size: 0.9rem;
						font-weight: 400;
						z-index: 3;
					}

					.circle {
						z-index: 2;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						width: 2.375rem;
					}

					&:hover {
						background: none;

						abbr {
							color: $accent-font;
						}

						.circle {
							background: $accent;
						}
					}

					&--neighboringMonth {
						opacity: 1;
					}

					&--weekend {
						abbr {
							color: $red;
						}
					}

					&--file {
						abbr {
							color: $danger-text;
						}

						.circle {
							background: $danger;
						}

						&:hover {
							.circle {
								background: linear-gradient(135deg, $danger 22.5%, $accent 25%, $accent 75%, $danger 77.5%);
							}
						}
					}

					&--depo-file {
						abbr {
							color: $warning-text;
						}

						.circle {
							background: $warning;
						}

						&:hover {
							.circle {
								background: linear-gradient(135deg, $warning 22.5%, $accent 25%, $accent 75%, $warning 77.5%);
							}
						}
					}
				}
			}
		}
	}

	@media (max-width: 480px) {
		.react-calendar__navigation {
			&__arrow {
				padding: 0 0.125rem;
			}

			&__label {
				font-size: 0.9rem;
			}
		}
	}
}

@mixin dtActionBtns() {
	margin: 0 0.25rem;
	width: 2rem;
	height: 2rem;

	&:last-child {
		margin: 0 0 0 0.25rem;
	}

	&:first-child {
		margin: 0 0.25rem 0 0;
	}

	i {
		color: $secondary-font;
	}
}

@mixin staffItem() {
	& > a {
		margin-top: 0.25rem;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-wrap: nowrap;
		text-decoration: none;
		color: $primary-font;
		padding: 0.5rem 0;
		font-size: 0.95rem;
		border-radius: $radius;

		.v__staff__item__avatar {
			flex: 0 0 2.5rem;
			padding: 0 0 0 0.5rem;
			display: flex;
			align-items: center;
			font-size: 2rem;

			img {
				width: 2rem;
				height: 2rem;
				border-radius: 50%;
				object-fit: cover;
				background: $bg;
				box-shadow: 0 0 0 2px $bg-card;
			}
		}

		.v__staff__item__name {
			font-size: 0.85rem;
		}

		.v__staff__item__role {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-right: 0.625rem;
		}

		&:hover {
			background: $bg;
		}
	}
}

@mixin colorPicker {
	&__add {
		border-radius: $mini-radius;
		&__form {
			display: flex;
			flex-wrap: wrap;
			&__fields {
				flex: 0 0 100%;
				display: flex;
				align-items: flex-end;

				& > button {
					margin-bottom: 0.625rem;
				}

				&__section {
					flex: 1;
					padding-right: 0.625rem;
					display: grid;
					grid-gap: 0 0.625rem;
					grid-template-columns: 1fr 1fr 1fr;
					grid-template-areas: "from   to   color";

					.from {
						grid-area: from;
					}

					.to {
						grid-area: to;
					}

					.color {
						grid-area: color;
					}
					@media (max-width: 500px) {
						grid-template-columns: 1fr 1fr 2.375rem;
						grid-template-areas: "from to color";
					}
				}
			}
		}

		&__controls {
			padding: 0.625rem 0 0 0;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			flex-wrap: wrap;

			&__add-row {
				flex: 1;
			}

			button {
				margin-right: 0.625rem;

				&:last-child {
					margin-right: 0;
				}
			}

			@media (max-width: 1000px) {
				&__add-row {
					flex: 0 0 100%;
					padding-bottom: 0.625rem;

					button {
						width: 100%;
					}
				}
			}
		}
	}

	&__saved {
		font-size: 0.9rem;
		overflow: hidden;

		@media (max-width: 1400px) {
			padding-top: 1rem;
		}

		&__item {
			overflow: hidden;
			border-bottom: 1px solid $dt-border;
			margin-bottom: 0.625rem;

			&:last-child {
				border-bottom: none;
				margin-bottom: 0;
			}

			&__title {
				display: flex;
				overflow: hidden;
				padding: 0.3125rem;

				button {
					margin-right: 0.625rem;
				}

				&__info {
					flex: 1;
					width: 100%;
					overflow: hidden;
					margin-bottom: 1rem;
					.main {
						font-size: 0.9rem;
						font-weight: 600;
						margin: 0;
						line-height: 1;
					}
					.desc {
						font-size: 0.8rem;
						opacity: 0.9;
					}
					.v__badge__list {
						margin: 0.3125rem 0 0 0;
					}
				}
			}
		}
	}
}

@mixin themeSwitch($size) {
	position: relative;
	bottom: unset;
	right: unset;
	width: $size * 1.75;
	height: $size;
	box-shadow: none;
	overflow: hidden;
	margin: auto;

	.v__theme__switch__label {
		display: flex;
		justify-content: center;
		align-content: center;

		.v__theme__switch__label__rail {
			cursor: pointer;
			width: 100%;
			position: relative;
			background: $bg-darker;
			border-radius: 5rem;
			height: $size;
		}

		.v__theme__switch__label__slider {
			transition: $mid;
			background: $accent;
			border-radius: 50%;
			width: $size - 0.5rem;
			height: $size - 0.5rem;
			position: absolute;
			top: 0.25rem;
			left: 0.25rem;

			i {
				transition: $mid;
				font-size: $size / 2;
				position: absolute;
				top: 50%;
				color: $accent-font;
			}

			.night {
				left: 5rem;
				transform: translate(-50%, -50%);
			}

			.sunny {
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
	}

	input:checked + .v__theme__switch__label {
		.v__theme__switch__label__rail {
			.v__theme__switch__label__slider {
				transition: $mid;
				left: calc(100% - #{$size - 0.2rem});

				.night {
					transition: $mid;
					left: calc(100% - #{$size - 0.75rem});
					transform: translate(0, -50%);
				}

				.sunny {
					transition: $mid;
					left: -5rem;
					transform: translate(-50%, -50%);
				}
			}
		}
	}
}

@mixin tableNoResults {
	.v__table__body__row__cell {
		&__value {
			margin: 0 auto;
			display: block;
			width: 100%;
			padding: 2rem 0;
			text-align: center;
			user-select: none;
			svg {
				user-select: none;
				height: 7.5rem;

				.backboard {
					fill: darken($bg, 1.25%);
				}

				.backboard-paper {
					fill: $bg-card;

					&.dim {
						fill: rgba($bg-card, 0.675);
					}
				}

				.clip,
				.clip-round {
					fill: $accent;
				}
			}
		}
	}
}

@mixin staffPanels {
	.v__staff__items {
		background: $bg-card;

		&__header {
			p {
				color: $accent-darker;
			}
		}
		& > button {
			margin-bottom: 0.3125rem;
			font-size: 0.9rem;
			&:last-child {
				margin-bottom: 0.625rem;
			}
		}
		& > .v__staff__item {
			&:last-child {
				margin-bottom: 0.625rem;
			}
		}
		.v__staff__parent {
			position: relative;
			overflow: hidden;
			overflow-y: auto;
			height: 100%;
			padding-bottom: 0.3125rem;
			&__title {
				margin: 0.625rem 0 0.625rem 0.625rem;
				color: $accent-darker;
				font-size: 1rem;
				line-height: 1;
			}
			& > button {
				margin-bottom: 0.3125rem;
				font-size: 0.9rem;
			}
		}

		&--toggled {
			height: calc(100% - 3.875rem);
		}
	}

	.v__staff__item__collapsible__container {
		border-radius: $radius;
		margin: 0.25rem 0 0 0;

		.v__staff__item__collapsible {
			&__header {
				padding: 0.625rem 0;
				background: $bg-card;
				user-select: none;

				&__title {
					display: flex;
					align-items: center;
					text-transform: uppercase;
					font-size: 0.8rem;
					font-weight: 600;
					color: rgba($secondary-font, 0.75);

					&:before {
						content: "";
						flex: 0 0 1.625rem;
						height: 1px;
						margin: 0 0.625rem;
						background: rgba($secondary-font, 0.3);
					}

					&:after {
						content: "";
						flex: 1;
						height: 1px;
						margin: 0 0.625rem;
						background: rgba($secondary-font, 0.3);
					}
				}
			}

			&__body {
				@include staffItem();
			}
		}
	}

	.v__staff__item {
		border-radius: $radius;
		&:last-child {
			margin-bottom: 0.3125rem;
		}

		@include staffItem();
	}
}
