.fade__animate {
	will-change: opacity;

	&-exit {
		opacity: 1;
	}

	&-exit-active {
		transition: $mid;
		opacity: 0;
	}

	&-enter {
		opacity: 0;
	}

	&-enter-active {
		transition: $mid;
		opacity: 1;
	}
}

.dropdown__animate {
	will-change: transform, opacity;

	&-exit {
		opacity: 1;
		pointer-events: unset;
		transform: translateY(0) scale(1);
	}

	&-exit-active {
		transition: $fast;
		opacity: 0;
		pointer-events: none;
		transform: translateY(-1rem) scale(0.95);
	}

	&-enter {
		opacity: 0;
		pointer-events: none;
		transform: translateY(-1rem) scale(0.95);
	}

	&-enter-active {
		transition: $fast;
		opacity: 1;
		pointer-events: unset;
		transform: translateY(0) scale(1);
	}
}

.collapse__animate {
	will-change: opacity, max-height;

	&-exit {
		pointer-events: none;
		max-height: 50rem;
		padding-bottom: 0.3125rem;
		padding-top: 0.625rem;
		opacity: 1;
		@media (max-width: 800px) {
			padding-bottom: 0.3125rem;
			padding-top: 0.625rem;
		}
	}

	&-exit-active {
		transition: $mid;
		max-height: 0;
		padding-bottom: 0;
		padding-top: 0;
		opacity: 0;
	}

	&-enter {
		pointer-events: none;
		max-height: 0;
		padding-bottom: 0;
		padding-top: 0;
		opacity: 0;
	}

	&-enter-active {
		pointer-events: auto;
		transition: $mid;
		max-height: 50rem;
		padding-bottom: 0.3125rem;
		padding-top: 0.625rem;
		opacity: 1;
		@media (max-width: 800px) {
			padding-bottom: 0.3125rem;
			padding-top: 0.625rem;
		}
	}
}

.simple__collapse__animate {
	will-change: opacity, max-height;

	&-exit {
		opacity: 1;
		max-height: 100rem;
	}

	&-exit-active {
		transition: $mid;
		opacity: 0;
		max-height: 0;
	}

	&-enter {
		opacity: 0;
		max-height: 0;
	}

	&-enter-active {
		transition: $mid;
		opacity: 1;
		max-height: 100rem;
	}
}
