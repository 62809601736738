//-----CALENDAR-----
.react-datepicker {
	width: 100%;
	background-color: $bg-card;
	border-radius: $mini-radius;
	user-select: none;
	// &__month-container {
	// }
	&__header {
		&__container {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0.625rem 0;
			& > * {
				margin: 0 0.3125rem;
			}
		}

		&__label {
			flex-grow: 1;
			text-align: center;
		}
	}
	&__month {
		display: flex;
		flex-wrap: wrap;
		padding-top: 0.325rem;
	}
	&__week {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-bottom: 0.625rem;
		&:last-child {
			padding-bottom: 0;
		}
		& > * {
			flex: 0 0 calc(100% / 8);
		}
		&-number {
			text-align: center;
			font-size: 0.9rem;
			color: $accent;
			font-weight: bold;
		}
	}
	&__day {
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		outline: none;
		&:hover,
		&:focus-visible {
			.react-datepicker__day__content {
				box-shadow: 0 0 0 0.25rem rgba($secondary-font, 0.25);
			}
		}
		&-names {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0.3125rem 0;
			font-weight: bold;
			font-size: 0.9rem;
		}
		&-name {
			flex: 0 0 calc(100% / 8);
			text-align: center;
			text-transform: uppercase;
			text-decoration: underline;
		}
		&__content {
			width: 2.375rem;
			height: 2.375rem;
			border-radius: $big-radius * 10;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		&--weekend {
			color: $red;
		}
		&--disabled {
			pointer-events: none;
			opacity: 50%;
		}
		&--highlighted {
			.react-datepicker__day__content {
				background-color: $warning;
				color: $warning-text;
			}
			&:hover,
			&:focus-visible {
				.react-datepicker__day__content {
					box-shadow: 0 0 0 0.25rem rgba($warning, 0.25);
				}
			}
		}
		&--keyboard-selected {
			.react-datepicker__day__content {
				background-color: $bg;
				color: $primary-font;
			}
			&.react-datepicker__day--highlighted {
				.react-datepicker__day__content {
					background: darken($warning, 7.5%);
					color: $warning-text;
				}
			}
		}
		&--selected {
			.react-datepicker__day__content {
				background-color: $accent;
				color: $accent-font;
			}
			&.react-datepicker__day--highlighted {
				.react-datepicker__day__content {
					background: linear-gradient(135deg, $warning 22.5%, $accent 25%, $accent 75%, $warning 77.5%);
					color: $accent-font;
				}
			}
		}
		&--today {
			font-weight: bold;
			.react-datepicker__day__content {
				box-shadow: 0 0 0 0.125rem $accent;
			}
			&:hover,
			&:focus-visible {
				.react-datepicker__day__content {
					box-shadow: 0 0 0 0.25rem rgba($accent, 0.25);
				}
			}
		}
	}

	&--horizontal {
		.react-datepicker {
			&__month-container {
				display: grid;
				justify-content: center;
			}
			&__header {
				display: none;
			}
			&__month {
				overflow: hidden;
				overflow-x: auto;
				flex-wrap: nowrap;
				padding: 0.3125rem 0.625rem;
			}
			&__week {
				width: auto;
				flex: 0 0 auto;
				display: flex;
				padding: 0;
			}
			&__day {
				&:hover,
				&:focus-visible {
					.react-datepicker__day__content {
						box-shadow: none;
						&__day {
							box-shadow: 0 0 0 0.25rem rgba($secondary-font, 0.25);
						}
					}
				}
				&-names {
					display: none;
				}
				&--outside-month {
					display: none;
				}
				&__content {
					border-radius: $mini-radius;
					width: unset;
					height: unset;
					flex-direction: column;
					padding: 0.3125rem;
					&__name {
						font-size: 0.7rem;
						font-weight: bold;
						margin-bottom: 0.3125rem;
					}
					&__day {
						width: 2.375rem;
						height: 2.375rem;
						border-radius: $big-radius * 10;
						display: grid;
						place-content: center;
					}
					&__weekday {
						color: $primary-font;
					}
					&__weekend {
						color: $red;
					}
				}
				&--weekend {
					color: $red;
				}
				&--disabled {
					pointer-events: none;
					opacity: 50%;
				}
				&--highlighted {
					.react-datepicker__day__content {
						background-color: unset;
						color: unset;
						&__day {
							background-color: $warning;
							color: $warning-text;
						}
					}
					&:hover,
					&:focus-visible {
						.react-datepicker__day__content {
							box-shadow: none;
							&__day {
								box-shadow: 0 0 0 0.25rem rgba($warning, 0.25);
							}
						}
					}
				}
				&--keyboard-selected {
					.react-datepicker__day__content {
						background-color: unset;
						color: unset;
						&__day {
							background-color: $bg;
							color: $primary-font;
						}
					}
					&.react-datepicker__day--highlighted {
						.react-datepicker__day__content {
							background: initial;
							color: initial;
							&__day {
								background: darken($warning, 7.5%);
								color: $warning-text;
							}
						}
					}
				}
				&--selected {
					.react-datepicker__day__content {
						background-color: unset;
						color: unset;
						&__day {
							background-color: $accent;
							color: $accent-font;
						}
					}
					&.react-datepicker__day--highlighted {
						.react-datepicker__day__content {
							background: initial;
							color: initial;
							&__day {
								background: linear-gradient(135deg, $warning 22.5%, $accent 25%, $accent 75%, $warning 77.5%);
								color: $accent-font;
							}
						}
					}
				}
				&--today {
					font-weight: bold;
					.react-datepicker__day__content {
						box-shadow: none;
						&__day {
							box-shadow: 0 0 0 0.125rem $accent;
						}
					}
					&:hover,
					&:focus-visible {
						.react-datepicker__day__content {
							box-shadow: none;
							&__day {
								box-shadow: 0 0 0 0.25rem rgba($accent, 0.25);
							}
						}
					}
				}
			}
		}
	}
}

.react-datepicker-popper {
	padding: 0 1.25rem 0 0;
	.react-datepicker {
		border: 1px solid rgba($secondary-font, 0.25);
		padding: 0.625rem;
	}
}
//-----MONTH / WEEK PICKER-----
.v__month__week__picker {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 0 0.25rem;
	width: auto;
	margin: 0 auto;

	&__container {
		width: 100%;
		display: grid;
		user-select: none;
		overflow: hidden;
		overflow-x: auto;
	}

	&__month {
		padding: 0.25rem;
		flex: 0 0 auto;

		&__item {
			text-align: center;
			border-radius: $mini-radius;
			border: 1px solid rgba($secondary-font, 0.1);
			background: $bg-card;
			overflow: hidden;

			&__label {
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 0.3125rem 1rem;
				background-color: $bg;
				border-radius: $mini-radius / 1.25 $mini-radius / 1.25 0 0;
				* {
					font-size: 0.9rem;
					padding: 0 0.25rem;
				}
				&:hover {
					box-shadow: 0 0 0 1px inset $accent;
				}
			}

			&--active {
				border: 1px solid $accent;
				.v__month__week__picker__month__item__label {
					background: $accent;
					* {
						color: $accent-font;
					}
				}
			}
		}
	}
	&__weeks {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0.25rem;
		&__item {
			font-family: "Roboto", sans-serif;
			padding: 0.125rem;
			button {
				font-size: 0.9rem;
				width: 2rem;
				line-height: 1.4;
				&:hover {
					box-shadow: 0 0 0 1px $accent;
				}
			}
		}
	}
}

//-----CALENDAR NAVIGATION-----
.v__calendar__full__nav {
	user-select: none;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.625rem;
	button {
		flex-shrink: 0;
	}
	// &__label {
	// }
	&__years {
		display: flex;
		align-items: center;
		padding: 0 0.625rem;
		overflow: hidden;
		overflow-x: auto;
	}
	&__year {
		cursor: pointer;
		padding: 0.25rem 0.625rem;
		text-transform: capitalize;
		border-radius: $mini-radius;
		margin: 0 0.125rem;
		&--selected {
			background: $accent;
			color: $accent-font;
			* {
				color: $accent-font;
			}
		}
	}
}
