//-----DATA TABLE CONTAINER-----

//TABLE
.v__table__container {
	position: relative;
	overflow-x: auto;
	&--padded {
		.v__card__body {
			padding: 1rem !important;
			@media (max-width: 800px) {
				padding: 0.625rem !important;
			}
			.v__table {
				border-collapse: separate;
				border-spacing: 0 0.25rem;
			}
		}
	}
}

@mixin members() {
	display: flex;
	align-items: center;
	max-width: unset !important;
	overflow: unset !important;
	width: 15.5rem;
	img {
		transition: $fast;
		flex: 0 0 2rem;
		height: 2rem;
		width: 2rem;
		margin-right: 0;
		margin-left: -0.25rem;
		z-index: 1;
		border-radius: 50%;
		box-shadow: 0 0 0 1px $bg;
		cursor: pointer;
		&:hover {
			z-index: 2;
			box-shadow: 0 0 0 1px $bg, 0 0 0 0.25rem rgba($secondary-font, 0.25);
		}
	}
	&__left {
		display: block;
		flex: 1;
		line-height: 1;
		margin-left: 0.625rem;
		font-size: 0.8rem;
		font-weight: 500;
	}
}

.v__table {
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
	border: none;
	margin-top: 0;
	&__header {
		&__row {
			&__cell {
				padding: 0.625rem 0.5rem;
				white-space: nowrap;
				text-align: left;
				font-size: 0.8rem;
				font-weight: 600;
				text-transform: uppercase;
				letter-spacing: 0.075rem;
				user-select: none;
				color: rgba($secondary-font, 0.75);
				background-color: darken($bg-card, 2);
				position: relative;

				&:first-child {
					border-radius: $mini-radius 0 0 $mini-radius;
				}
				&:last-child {
					border-radius: 0 $mini-radius $mini-radius 0;
					text-align: right;
				}

				&--sticky {
					position: sticky;
					left: 0;
					z-index: 5 !important;
					background: $bg-card;
					background-color: darken($bg-card, 2);
				}

				&::before {
					pointer-events: none;
					content: "";
					position: absolute;
					left: 0.5rem;
					right: 0.5rem;
					width: calc(- 1rem);
					height: 0;
					border-radius: $mini-radius;
					top: 0;
					background-color: $accent-darker;
				}
				&--desc {
					&::before {
						top: calc(100% - 0.25rem);
						height: 0.2rem;
					}
				}

				&--asc {
					&::before {
						top: 0;
						height: 0.2rem;
					}
				}
			}
		}
	}
	&__body {
		&__row {
			&:first-child {
				td {
					&::before {
						display: none;
					}
				}
			}

			.v__table__body__row__cell {
				background: $bg-card;
			}

			@media (pointer: fine) {
				&:hover {
					.v__table__body__row__cell {
						background-color: $dt-row-hover;
					}
				}
			}

			&__cell {
				padding: 0.5rem;
				white-space: nowrap;
				text-align: left;
				font-size: 0.9rem;
				position: relative;
				line-height: 1;
				small {
					display: block;
				}
				@media (max-width: 800px) {
					div,
					small,
					p {
						overflow: hidden;
						max-width: 20ch;
						text-overflow: ellipsis;
					}
				}

				&::before {
					pointer-events: none;
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					height: 1px;
					width: 100%;
					background: $dt-border;
					transform: translateY(-0.15rem);
				}
				&:first-child {
					border-radius: $mini-radius 0 0 $mini-radius;
				}
				&:last-child {
					border-radius: 0 $mini-radius $mini-radius 0;
				}

				&--sticky {
					position: sticky;
					left: 0;
					z-index: 5;
					@media (max-width: 800px) {
						max-width: 25ch;
						text-overflow: ellipsis;
						* {
							overflow: hidden;
							max-width: 25ch;
							text-overflow: ellipsis;
						}
					}
				}

				&__avatar__container {
					display: flex;
					align-items: center;
					overflow: hidden;
					text-overflow: ellipsis;
					img {
						display: block;
						width: 2.5rem;
						height: 2.5rem;
						object-fit: cover;
						border-radius: 50%;
						margin-right: 0.625rem;
					}
					i {
						width: 2.5rem;
						font-size: 2.5rem;
						margin-right: 0.625rem;
					}
					&__info {
						flex: 1;
						line-height: 1;
						.avatar__container__info__main {
							&--clickable {
								cursor: pointer;
								&:hover {
									color: $accent-darker;
								}
							}
						}
					}
					@media (max-width: 800px) {
						img {
							width: 2rem;
							height: 2rem;
						}
						i {
							width: 2rem;
							font-size: 2rem;
						}
					}
				}

				&__members {
					@include members();
					width: 15rem;
				}

				&__chart {
					display: flex;
					align-items: center;
					@media (max-width: 1200px) {
						overflow: visible !important;
						max-width: 25ch !important;
						* {
							overflow: visible !important;
						}
					}
					&__info {
						display: flex;
						align-items: center;
						padding-right: 1rem;
						width: 7.5rem;
						p {
							font-size: 0.8rem;
							line-height: 1.4;
							margin: 0;
						}
						i {
							font-size: 1.2rem;
							margin-right: 0.625rem;
						}
					}
				}

				&__division {
					display: flex;
					align-items: center;
					* {
						margin: 0;
					}
					&__icon {
						margin-right: 0.5rem;
						font-size: 1.1rem;
					}
					p {
						display: flex;
						align-items: center;
						small {
							margin-right: 0.5rem;
						}
						span {
							font-family: "Roboto", sans-serif;
							font-size: 0.85rem;
						}
					}
				}

				&__actions-list {
					display: flex;
					align-items: center;
					justify-content: flex-end;
					& > button {
						width: 2rem;
						margin: 0 0.5rem 0 0;
						&:last-child {
							margin: 0;
						}
					}
				}
			}

			&--empty {
				&:nth-child(odd) {
					.v__table__body__row__cell {
						&::before {
							height: 0;
						}
						@media (max-width: 800px) {
							max-width: unset !important;
						}
						background-color: $bg-card;
					}

					&:hover {
						.v__table__body__row__cell {
							background-color: $bg-card;
						}
					}
				}

				@include tableNoResults();
			}
		}
	}
}

@mixin filteredCol() {
	&::after {
		content: "";
		pointer-events: none;
		position: absolute;
		z-index: 10;
		top: 0;
		left: -1px;
		right: 1px;
		width: 100%;
		height: 400rem;
		background: rgba($accent-darker, 0.1);
	}
}

.v__report__table {
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
	border: none;

	&__header {
		&__row {
			&__cell {
				position: relative;
				padding: 0.5rem;
				white-space: nowrap;
				text-align: left;
				font-size: 0.8rem;
				font-weight: 500;
				box-shadow: inset -1px -1px 0 0 $dt-border;
				user-select: none;
				color: rgba($secondary-font, 0.75);

				i {
					font-size: 0.8rem;
					vertical-align: middle;
				}

				&:last-child {
					box-shadow: inset 0 -1px 0 0 $dt-border;
				}

				&--sticky {
					position: sticky;
					left: 0;
					z-index: 3 !important;
					background: $bg-card;
				}

				&--filtered {
					position: relative;
					@include filteredCol();
				}

				&--desc {
					box-shadow: inset -1px -1px 0 0 $dt-border, inset 0 -0.2rem 0 0 $accent-darker;
					@include filteredCol();

					&:last-child {
						box-shadow: inset 0 -1px 0 0 $dt-border, inset 0 -0.2rem 0 0 $accent-darker;
					}
				}

				&--asc {
					box-shadow: inset -1px -1px 0 0 $dt-border, inset 0 0.2rem 0 0 $accent-darker;
					@include filteredCol();

					&:last-child {
						box-shadow: inset 0 -1px 0 0 $dt-border, inset 0 0.2rem 0 0 $accent-darker;
					}
				}
			}
		}
	}

	&__body {
		&__row {
			&:nth-child(odd) {
				.v__report__table__body__row__cell {
					background-color: $dt-row-bg;
				}
			}

			.v__report__table__body__row__cell {
				background: $bg-card;
			}

			@media (pointer: fine) {
				&:hover {
					.v__report__table__body__row__cell {
						background-color: $dt-row-hover;
					}

					&:nth-child(odd) {
						.v__report__table__body__row__cell {
							background-color: $dt-row-hover;
						}
					}
				}
			}

			&--hover {
				@media (pointer: fine) {
					&:hover > td {
						box-shadow: inset 0 1px 0 0 $accent, inset 0 -1px 0 0 $accent, inset -1px 0 0 0 $dt-border;

						&.v__report__table__body__row__cell--clickable-redirect {
							box-shadow: inset 0 1px 0 0 $warning, inset 0 -1px 0 0 $warning, inset -1px 0 0 0 $dt-border;
						}
						&.v__report__table__body__row__cell--clickable-action {
							box-shadow: inset 0 1px 0 0 $success, inset 0 -1px 0 0 $success, inset -1px 0 0 0 $dt-border;
						}
						&.v__report__table__body__row__cell--clickable-points {
							box-shadow: inset 0 1px 0 0 $green, inset 0 -1px 0 0 $green, inset -1px 0 0 0 $dt-border;
						}
					}
				}
			}

			&--single-hover {
				.v__report__table__body__row__cell--clickable {
					cursor: pointer;
					@media (pointer: fine) {
						&:hover {
							box-shadow: inset 0 0 0 1px $accent, inset -1px 0 0 0 $dt-border;
						}
					}
					&-redirect {
						@media (pointer: fine) {
							&:hover {
								box-shadow: inset 0 0 0 1px $warning, inset -1px 0 0 0 $dt-border;
								text-decoration: underline;
							}
						}
					}
					&-action {
						@media (pointer: fine) {
							&:hover {
								box-shadow: inset 0 0 0 1px $warning, inset -1px 0 0 0 $dt-border;
								text-decoration: underline;
							}
						}
					}
					&-points {
						@media (pointer: fine) {
							&:hover {
								box-shadow: inset 0 0 0 1px $green, inset -1px 0 0 0 $dt-border;
								text-decoration: underline;
							}
						}
					}
				}
			}

			&__cell {
				padding: 0 0.5rem;
				height: 2.5rem;
				white-space: nowrap;
				text-align: left;
				font-size: 0.85rem;
				box-shadow: inset -1px 0 0 0 $dt-border;
				font-family: "Roboto", sans-serif;
				max-width: 15ch;
				overflow: hidden;
				text-overflow: ellipsis;

				* {
					font-family: "Roboto", sans-serif;
				}

				&:last-child {
					box-shadow: none;
				}

				&--clickable {
					cursor: pointer;
					@media (pointer: fine) {
						&:hover {
							box-shadow: inset 0 0 0 1px $accent, inset -1px 0 0 0 $dt-border;
						}
					}
					&-redirect {
						@media (pointer: fine) {
							&:hover {
								position: sticky;
								text-decoration: none;
								transform: scale(1.1);
								z-index: 3;
								box-shadow: inset 0 0 0 1px $warning, $big-shadow !important;
							}
						}
					}
					&-action {
						@media (pointer: fine) {
							&:hover {
								position: sticky;
								text-decoration: none;
								transform: scale(1.1);
								z-index: 3;
								box-shadow: inset 0 0 0 1px $success, $big-shadow !important;
							}
						}
					}
					&-points {
						@media (pointer: fine) {
							&:hover {
								position: sticky;
								text-decoration: none;
								transform: scale(1.1);
								z-index: 3;
								box-shadow: inset 0 0 0 1px $green, $big-shadow !important;
							}
						}
					}
				}

				&--limited {
					max-width: 50ch;
					&[data-expanded="true"] {
						box-shadow: inset 0 0 0 1px $warning;
					}
				}

				&--division {
					text-align: center;
				}

				&--sticky {
					position: sticky;
					left: 0;
					z-index: 1;
					@media (max-width: 800px) {
						max-width: 9rem;
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}

				&--colorful {
					& > span {
						position: relative;
						white-space: nowrap;
						display: flex;
						align-items: baseline;
					}

					.cell__pigment {
						position: absolute;
						bottom: -0.25rem;
						width: 100%;
						height: 0.25rem;
					}
				}
			}
		}

		&--with-avg {
			tr:nth-child(1) {
				td {
					box-shadow: inset 0 2px 0 0 $warning, inset -1px 0 0 0 $dt-border;

					&:last-child {
						box-shadow: inset 0 2px 0 0 $warning;
					}
				}
			}

			tr:nth-child(2) {
				td {
					box-shadow: inset 0 -2px 0 0 $warning, inset -1px 0 0 0 $dt-border;

					&:last-child {
						box-shadow: inset 0 -2px 0 0 $warning;
					}
				}
			}
		}
	}
}

//PAGINATION
.v__table__pagination {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;

	&__page__results {
		flex: 1;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding: 0;
		font-size: 0.9rem;
		line-height: 1;

		.v__form__group {
			padding-right: 0.3125rem;
			margin: 0;
		}

		& > span,
		& > label,
		& > strong {
			padding-right: 0.3125rem;
		}
	}

	&__pages {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		flex-wrap: wrap;
		padding: 0;

		& > span {
			font-size: 0.9rem;
			padding-right: 0.3125rem;

			& > span,
			& > strong {
				padding-right: 0.3125rem;
			}
		}

		&__group {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			box-shadow: $mini-shadow;

			&__btn {
				width: 2rem;
				border-radius: 0;

				& > i {
					font-size: 1.15rem;
				}

				&:first-child {
					border-radius: $mini-radius 0 0 $mini-radius;
				}

				&:last-child {
					border-radius: 0 $mini-radius $mini-radius 0;
				}
			}
		}
	}

	@media screen and (max-width: 800px) {
		&__page__results {
			flex: 0 0 100%;
			justify-content: center;
			padding: 0 0 0.5rem 0;
		}
		&__pages {
			flex: 0 0 100%;
			justify-content: center;

			& > span {
				flex: 0 0 100%;
				text-align: center;
				padding: 0 0 0.625rem 0;
			}
		}
	}
}

//PREVIEW
.v__table__preview {
	position: sticky;
	top: 3.5rem;
	width: 100%;
	height: 0;
	overflow: visible;
	z-index: 99999;

	&__card {
		max-width: 45rem;
		margin: 0 auto;
		transform: translateY(1rem);
		box-shadow: $big-shadow;
		border: 1px solid rgba($secondary-font, 0.1);

		.v__card__header {
			&__container {
				span {
					& > div {
						margin-left: auto;
						margin-right: 1rem;
					}
				}
			}
		}

		.v__card__body {
			max-height: 50vh;
			overflow-y: auto;
			font-size: 0.9rem;
		}
	}
}

//-----DATA TABLE CONTAINER-----
