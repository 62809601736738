.v__auth__logo__watermark {
	position: fixed;
	z-index: 1;
	width: 50vw;
	opacity: 0.025;
	transform: rotate(35deg);
	bottom: 0;
	left: 0;
	* {
		fill: $primary-font;
	}
}

.v__auth__content__wrapper {
	z-index: 2;
	max-width: 60rem;
	width: 100%;
	margin: 0 auto;
	min-height: 100vh;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	.v__auth__brand {
		padding: 2rem 1rem;
		flex: 0 0 auto;
		svg {
			fill: $bg-card;
			width: 8rem;

			.accent-wing {
				fill: $logo-bg;
			}

			.rest-wings {
				fill: $accent-darker;
			}

			.characters {
				fill: $primary-font;
			}
		}
	}
	.v__auth__form__container {
		padding: 2rem 1rem;
		flex: 1;
		display: grid;
		align-content: center;
		.auth__form__card {
			max-width: 32.5rem;
			padding: 2rem 1rem;
			margin: 0;
		}
	}
	.v__auth__footer {
		flex: 0 0 auto;
		padding: 2rem 1rem;
		color: $primary-font;
	}
	.snumber__matched {
		background: $bg;
		border-radius: $mini-radius;
		padding: 0.625rem;
		margin-bottom: 0.625rem;
		img {
			flex: 0 0 2rem;
			width: 2rem;
			height: 2rem;
			border-radius: 50%;
			object-fit: cover;
		}
		&__name {
			flex: 1;
			color: $primary-font;
			padding: 0 0.625rem;
		}
		&__container {
			display: flex;
			align-items: center;
			margin-bottom: 0.625rem;
		}
		&__relations {
			padding: 0.3125rem;
			small {
				margin-right: 0.625rem;
			}
		}
	}
}

.v__auth__about__wrapper {
	z-index: 2;
	flex: 0 0 28rem;
	min-height: 100vh;
	background: darken($bg, 5%);
	color: $primary-font;
	display: grid;
	align-content: center;
	padding: 3.5rem;
	.auth__graphic {
		width: 20rem;
		height: auto;
		.a {
			fill: url(#a);
		}
		.b {
			fill: $bg-card;
		}
		.c {
			fill: $accent;
		}
		.d {
			fill: $warning;
		}
		.e {
			fill: $secondary-font;
		}
		.f,
		.g,
		.h,
		.i {
			fill: none;
			stroke: $warning;
			stroke-miterlimit: 10;
			stroke-width: 5px;
		}
		.g {
			stroke-dasharray: 11.54 11.54;
		}
		.h {
			stroke-dasharray: 11.45 11.45;
		}
		.i {
			stroke-dasharray: 12;
			opacity: 0.5;
		}
		.j {
			fill: $bg-card;
		}
		.k {
			fill: $bg-card;
		}
	}
	.v__auth__about {
		&__services {
			margin: 2rem 0;
			padding: 0;
			list-style-type: none;
			& > li {
				display: flex;
				align-items: center;
				padding: 0.625rem 0;
				& > i {
					margin-right: 1rem;
					color: $accent-darker;
				}
				&:last-child {
					margin-left: 2.5rem;
				}
			}
		}
	}
}

.v__auth__form__footer {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	align-items: center;
	gap: 0.625rem;
	a {
		text-decoration: underline;
	}
	.v__register__btn {
		margin-top: 0.625rem;
	}
}

.v__pass__req {
	padding: 0.625rem;
	margin: 0.625rem 0 0 0;
	background: $bg-card;
	border-radius: $mini-radius;
	box-shadow: 0 0 0 0.0625rem rgba($primary-font, 0.1);
	list-style-type: none;
	color: $primary-font;
	& > li {
		display: flex;
		align-items: center;
		padding: 0.3125rem 0;
		font-size: 0.9rem;
		svg {
			font-size: 1.25rem;
			margin-right: 0.625rem;
		}
	}
}

.v__heading {
	font-size: clamp(2rem, 4vw, 4rem);
	font-weight: 200;
	margin: 0 0 1rem 0;
}

.v__claimed__hint {
	color: $danger;
}

[class*="v__badge"] {
	display: inline-flex;
}

@media (max-width: 1450px) {
	.v__auth__about__wrapper {
		display: none;
	}
	.v__auth__logo__watermark {
		display: none;
	}
}

@media (max-width: 400px) {
	.v__auth__content__wrapper {
		padding: 0;
	}
}
