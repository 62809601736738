//-----CHARTS-----
.apexcharts-canvas {
	.apexcharts-svg {
		cursor: pointer;

		text {
			fill: $primary-font;
			cursor: auto;
		}

		.apexcharts-data-labels {
			text {
				fill: #fff;
			}
		}

		.apexcharts-legend {
			cursor: auto;
			@media (min-width: var(--sm)) {
				height: 100%;
				display: grid;
				align-items: center;
				top: 50% !important;
				transform: translateY(-50%);
			}
			&-marker {
				width: 10px !important;
				height: 10px !important;
				margin-right: 5px;
			}
			.apexcharts-legend-series {
				.apexcharts-legend-text {
					color: $primary-font !important;
				}
			}
		}

		.apexcharts-inner {
			.apexcharts-xaxis {
				.apexcharts-xaxis-texts-g {
					text {
						fill: $primary-font;
					}
				}
			}
			.apexcharts-grid {
				line {
					stroke: $dt-border;
				}
			}
			.apexcharts-yaxis-annotations {
				rect {
					fill: $danger;
				}
				.apexcharts-yaxis-annotation-label {
					fill: $danger-text;
				}
			}
		}

		.apexcharts-yaxis {
			cursor: auto;
			.apexcharts-yaxis-texts-g {
				text {
					fill: $secondary-font;
				}
			}
			.apexcharts-yaxis-title {
				text {
					fill: $secondary-font;
				}
			}
		}
	}

	.apexcharts-tooltip {
		border: 1px solid $dt-border;
		box-shadow: $mini-shadow;
		border-radius: $radius;

		&-title {
			background: $bg-card;
			font-weight: 600;
			border-bottom: 1px solid $dt-border;
			color: $primary-font;
		}

		background: $bg-card;
		color: $primary-font;

		&-series-group {
			background: $bg-card !important;
		}
	}

	.apexcharts-xaxistooltip {
		background: $bg-card;
		color: $primary-font;
		border-color: $dt-border;
		box-shadow: $mini-shadow;
		border-radius: $radius;

		&:before {
			border-bottom-color: $dt-border;
		}

		&:after {
			border-bottom-color: $bg-card;
		}
	}
}

.chart__config {
	display: flex;
	.v__checkbox {
		& > label {
			padding: 0;
			& > span {
				font-size: 0.9rem;
			}
		}
	}
}

//-----CHARTS END-----
