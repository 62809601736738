//-----AVATAR-----
.v__icon__avatar {
	border-radius: 50%;
	color: $avatar-accent;
}
//-----AVATAR END-----

//-----ALERT-----
$alerts: "accent" $accent $accent-font, "success" $success $success-text, "warning" $warning $warning-text, "danger" $danger $danger-text;

.v__alert {
	display: block;
	width: 100%;
	border-radius: $mini-radius;
	padding: 1rem;
	text-align: center;
	margin-bottom: 1rem;
	line-height: 1;
	font-size: 1rem;

	@each $name, $color, $font in $alerts {
		&.v__alert--#{$name} {
			background: $color;
			color: $font;
		}
	}
}
//-----ALERT END-----

//-----BADGE-----

.v__badge {
	@include badge($bg, $secondary-font);
	@include badgeSize();

	&--accent {
		@include badge($accent, $accent-font);
		@include badgeSize();
	}

	&--danger {
		@include badge($danger, $danger-text);
		@include badgeSize();
	}

	&--success {
		@include badge($success, $success-text);
		@include badgeSize();
	}

	&--warning {
		@include badge($warning, $warning-text);
		@include badgeSize();
	}

	&--outline {
		@include badge($bg, $secondary-font);
		@include badgeSize();
		box-shadow: inset 0 0 0 1px $accent;
	}
}

.v__badge__list {
	&--wrapable {
		flex-wrap: wrap;
		& > div {
			margin: 0 0.5rem 0.5rem 0;
			&:last-child {
				margin: 0 0 0.5rem 0;
			}
		}
	}
}

.v__drag__list {
	position: relative;
	display: flex;
	align-items: center;
	// &::-webkit-scrollbar {
	// 	display: none;
	// }
	// -ms-overflow-style: none; /* IE and Edge */
	// scrollbar-width: none;
	// overflow: hidden;
	&__container {
		position: relative;
		display: flex;
		align-items: center;
		width: 100%;
		z-index: 2;
		& > * {
			flex: 0 0 auto;
			margin: 0 0.3125rem 0 0;
			&:last-child {
				margin: 0;
			}
		}
	}

	&__scroll-helper {
		z-index: 3;
		user-select: none;
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: unset;
		height: 100%;
		background: $bg-card;
		background: linear-gradient(90deg, transparent 0%, $bg-card 20%);
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		i {
			font-size: 1.5rem;
			padding: 0 0.25rem;
		}
		&--reverse {
			right: unset;
			left: 0;
			background: linear-gradient(270deg, transparent 0%, $bg-card 20%);
		}
	}
}

//-----BADGE END-----

//-----THEME SWITCH-----
.v__theme__switch {
	user-select: none;
	z-index: 999999;
	border-radius: 5rem;

	@include themeSwitch(2.25rem);

	&--small {
		@include themeSwitch(1.75rem);
	}

	& > input {
		opacity: 0;
		pointer-events: none;
		position: absolute;
	}
}

//-----THEME SWITCH END-----

//-----PAGE SEPARATOR-----
.v__page__separator {
	width: 100%;
	display: flex;
	align-items: center;
	text-transform: capitalize;
	font-size: 1.1rem;
	font-weight: 500;
	color: $primary-font;
	margin: 1rem 0 2rem 0;

	&:before {
		content: "";
		flex: 0 0 2rem;
		height: 1px;
		margin: 0 1rem;
		background: rgba($secondary-font, 0.3);
	}

	&:after {
		content: "";
		flex: 1;
		height: 1px;
		margin: 0 1rem;
		background: rgba($secondary-font, 0.3);
	}

	@media (max-width: 1200px) {
		margin: 0.375rem 0 1rem 0;
		&:before {
			margin: 0 0.625rem;
		}
		&:after {
			margin: 0 0.625rem;
		}
	}
}

//-----PAGE SEPARATOR END-----

//-----HINT-----
.v__card__header__container__hint {
	@include tooltip();

	p {
		font-size: 0.9rem;
		margin: 0;
	}
}
//-----HINT END-----

//-----TABS-----
.v__tabs {
	list-style-type: none;
	margin: 0 0 0.625rem 0;
	padding: 0 0 0 0.625rem;
	text-align: center;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
	width: 100%;
	box-shadow: 0 1px 0 0 rgba($secondary-font, 0.1);

	.v__tabs__item {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0 1rem;
		height: 2.375rem;
		background: $bg;
		border-radius: $mini-radius;
		cursor: pointer;
		margin: 0 0.625rem 0.625rem 0;
		font-size: 0.9rem;
		white-space: nowrap;

		@media (max-width: 600px) {
			flex: 1;
		}

		&--selected {
			background: $accent;
			color: $accent-font;
		}
	}
}

//-----TABS END-----

//-----DROPDOWN-----
.v__dropdown {
	transition: $fast;
	position: absolute;
	background: $bg-card;
	border-radius: $mini-radius;
	border: 1px solid $dt-border;
	min-width: 12.5rem;
	width: 100%;
	right: 0;
	top: calc(100% + 0.3125rem);
	transform-origin: center top;
	z-index: 999999;
	padding: 0.3125rem;
	box-shadow: $big-shadow;
	&__overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: transparent;
		z-index: 999998;
	}
}
//-----DROPDOWN END-----

.section-separator {
	width: 95%;
	height: 1px;
	background: $dt-border;
	margin: 0 auto 0.3125rem auto;
}

//-----NOTIFICATIONS-----
.v__notifications {
	height: 45vh;
	overflow: hidden;
	overflow-y: auto;
	&__item {
		position: relative;
		padding-bottom: 0.3125rem;
		&__content {
			padding: 0.625rem 1rem;
			border-radius: $mini-radius;
			background: lighten($bg, 1%);
			&:hover {
				background: $bg;
			}
			&__header {
				display: flex;
				padding-top: 0.3125rem;
				user-select: none;
				i {
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 1rem;
					width: 2rem;
					height: 2rem;
					border-radius: 50%;
				}
				&__title {
					flex: 1;
					padding: 0 0 0 1rem;
					p {
						display: block;
						margin: 0;
						font-weight: 500;
					}
					span {
						display: block;
						font-size: 0.75rem;
						color: $secondary-font;
					}
				}
			}
			&__body {
				&__msg {
					padding: 0.5rem 0 0.3125rem 0;
					line-height: 1.5;
					font-size: 0.9rem;
				}
				button {
					margin-bottom: 0.3125rem;
				}
			}
			&.unread::after {
				content: "";
				display: block;
				position: absolute;
				top: 0.5rem;
				right: 0.5rem;
				width: 0.625rem;
				height: 0.625rem;
				background: $accent;
				border-radius: 50%;
			}
		}
	}
}
//-----NOTIFICATIONS END-----
//-----BACK TO TOP-----
.v__to-top {
	transition: $fast;
	position: fixed;
	bottom: 1rem;
	right: 1rem;
	z-index: 999999;
}

//-----BACK TO TOP END------

//-----VENIWAY TABS-----
.v__tabs__container {
	position: relative;
	margin-bottom: 0.625rem;

	.v__tabs__headers {
		position: relative;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-wrap: wrap;

		.v__tabs__list {
			flex: 1;
			position: relative;
			display: flex;
			padding: 0;
			justify-content: flex-start;
			align-items: stretch;

			.v__tab__selector {
				position: absolute;
				height: 100%;
				bottom: 0;
				background: $accent;
				z-index: 1;
				border-radius: $mini-radius;
				transition: $mid;
			}

			.v__tabs__list__item {
				position: relative;
				z-index: 2;
				height: 2.375rem;
				padding: 0 0.625rem;
				margin-right: 0.625rem;
				display: flex;
				align-items: center;
				cursor: pointer;
				transition: $mid;
				color: $primary-font;
				font-size: 1rem;
				font-weight: 500;
				opacity: 0.5;

				&:focus {
					outline: none;
				}

				&:hover,
				&:focus-visible {
					background: lighten($bg, 1%);
				}

				&--active {
					transition: $mid;
					opacity: 1;
					color: $accent-font;
					&:hover,
					&:focus-visible {
						background: transparent;
					}
				}

				&:last-child {
					margin-right: 0;
				}
			}

			@media (max-width: 800px) {
				flex: 0 0 100%;
				margin-bottom: 0.625rem;
			}
		}

		.v__tabs__list__extra-action {
			@media (max-width: 800px) {
				flex: 0 0 100%;
			}
		}
	}

	&--vertical {
		.v__tabs__headers {
			.v__tabs__list {
				display: block;

				.v__tabs__list__item {
					margin-bottom: 0.625rem;
					width: 100%;
				}
			}
		}
	}

	&--no-animations {
		.v__tabs__headers {
			.v__tabs__list {
				.v__tab__selector {
					transition: none;
				}
			}
		}
	}
}

.v__report__add__tabs {
	.v__tabs__headers {
		.v__tabs__list {
			.v__tabs__list__item {
				flex: 1;
				height: auto;
				padding: 0.625rem;
				margin-right: 0.625rem;
				flex-wrap: wrap;

				&__label {
					flex: 0 0 100%;
					font-size: 1rem;
					font-weight: 500;
					display: flex;
					align-items: center;
					justify-content: center;
					text-align: center;

					i {
						font-size: 1.25rem;
						padding-right: 0.625rem;
						@media (max-width: 800px) {
							display: none;
						}
					}
				}
			}

			@media (max-width: 800px) {
				flex: 0 0 100%;
				margin-bottom: 0.625rem;
			}
		}

		.v__tabs__list__extra-action {
			@media (max-width: 800px) {
				flex: 0 0 100%;
			}
		}
	}
}

//-----VENIWAY TABS END-----

//-----REPORT PIGMENTS-----
.v__report__pigments {
	&__item {
		position: relative;
		width: 100%;
		background: lighten($bg, 1%);
		user-select: none;
		padding: 1rem;
		margin-bottom: 0.3125rem;
		border-radius: $mini-radius;
		&__info {
			cursor: pointer;

			&:focus {
				outline: none;
			}

			&:focus-visible {
				box-shadow: 0 0 0 1px $secondary-font;
			}

			p {
				display: block;
				line-height: 1;
				margin: 0;
				font-weight: 500;
			}
			small {
				display: block;
				line-height: 1;
				opacity: 0.8;
				color: $secondary-font;
				padding: 0.25rem 0 0.625rem 0;
			}
		}
		&__data {
			.v__drag__list {
				&__scroll-helper {
					background: lighten($bg, 1%);
					background: linear-gradient(90deg, transparent 0%, lighten($bg, 1%) 20%);
					&--reverse {
						background: linear-gradient(270deg, transparent 0%, lighten($bg, 1%) 20%);
					}
				}
			}
		}
		&:hover {
			background: $bg;
			.v__drag__list {
				&__scroll-helper {
					background: $bg;
					background: linear-gradient(90deg, transparent 0%, $bg 20%);
					&--reverse {
						background: linear-gradient(270deg, transparent 0%, $bg 20%);
					}
				}
			}
		}
	}
	&__entry {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		padding-left: 0 !important;
		padding-right: 0 !important;
		.v__form__button__list {
			display: flex;
			align-items: center;
			@media (max-width: 600px) {
				flex-wrap: wrap;
			}
			& > * {
				margin-right: 0.3125rem;
			}
			& > *:last-child {
				margin-right: 0;
				margin-left: auto;
			}
			& > .presets {
				&:hover {
					transform: scale(0.95);
				}
			}
		}
		& > div {
			flex: 1;
			padding: 0 0.625rem;
			@media (max-width: 600px) {
				&.v__report__pigments__entry__from {
					flex: 0 0 50%;
				}
				&.v__report__pigments__entry__to {
					flex: 0 0 50%;
				}
				&.v__report__pigments__entry__colors {
					flex: 0 0 100%;
					border-bottom: 1px solid rgba($secondary-font, 0.1);
					padding-bottom: 1rem;
					margin-bottom: 0.625rem;
				}
			}
		}
	}
	&__add {
		text-align: center;
		user-select: none;
		margin: 0.625rem 0;
		p {
			margin: 0;
			font-size: 0.9rem;
		}
		& > button {
			margin: 0 auto;
		}
	}
}
//-----REPORT PIGMENTS END-----

.v__performance__nav {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 0.625rem;
	&__current {
		font-size: 0.9rem;
		margin: 0 0.5rem;
	}
}

//-----COMING SOON ELEMENT-----
.v__coming-soon__container {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	min-height: 26rem;

	span {
		padding: 1rem 2rem;
		font-size: 1.15rem;
		font-weight: 500;
		color: $primary-font;
	}
}

.v__coming-soon__graphic {
	padding: 1rem 0 2rem 0;
	width: 17.5rem;

	.a {
		fill: $bg;
	}

	.b {
		fill: $warning;
	}

	.c {
		fill: $accent;
	}

	.note {
		fill: $accent;
	}

	.d {
		fill: $accent-darker;
	}

	.e {
		fill: #fff;
	}
}

.pdf__logo,
.csv__logo,
.excel__logo {
	height: 3rem;
}

//-----LOGOS END-----
