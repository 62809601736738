//-----INPUT-----
.v__input {
	@include inputDefaults(2.375rem);

	&--sm {
		@include inputDefaults(2rem);
	}
}
//-----INPUT END-----

//-----INPUT GROUP-----
.v__input__group {
	position: relative;
	.v__input {
		flex: 1;
		padding-right: 2.625rem;
	}
	&__add-on {
		position: absolute;
		top: 50%;
		right: 0.3125rem;
		transform: translateY(-50%);
		width: 2rem;
	}
}
//-----INPUT GROUP END-----

//-----SELECT-----
.v__select {
	@include selectDefaults(2.375rem, $bg-darker);

	&--sm {
		@include selectDefaults(2rem, $bg-darker);
	}

	&--light {
		@include selectDefaults(2.375rem, $bg-card-darker);
		box-shadow: $mini-shadow;
	}

	&--light--sm {
		@include selectDefaults(2rem, $bg-card-darker);
		box-shadow: $mini-shadow;
	}

	@include selectGroupSeparatorDefaults();
	@include selectMenuDefaults();
}
//-----SELECT END-----

//-----QUICK DATE INPUT-----
.v__date__picker {
	&__btn {
		position: absolute;
		bottom: 0;
		width: 4rem;
		z-index: 5;

		i {
			color: $secondary-font;
		}

		&--prev {
			left: 0.625rem;
		}

		&--next {
			right: 0.625rem;
		}
	}

	.react-datepicker-wrapper {
		.react-datepicker__input-container {
			& > .v__input {
				text-align: center;
				padding: 0;
			}
		}
	}
}
//-----QUICK DATE INPUT END-----

//-----FILE INPUT-----
.v__file {
	@include inputDefaults(2.375rem);

	text-indent: 0;
	display: flex;
	cursor: pointer;

	&--valid {
		box-shadow: inset 0 0 0 1px $success;
	}

	&--invalid {
		box-shadow: inset 0 0 0 1px $danger;
	}

	&__instruction {
		flex: 0 0 auto;
		display: flex;
		justify-content: center;
		align-items: center;
		height: calc(100% - 0.625rem);
		margin: 0.3125rem 0 0 0.3125rem;
		background: $accent;
		color: $accent-font;
		border-radius: $mini-radius;
		padding: 0 1rem;
		pointer-events: none;
	}

	&__uploaded {
		flex: 1;
		height: 100%;
		overflow: hidden;
		&__item {
			height: 100%;
			display: flex;
			align-items: center;
			overflow: hidden;
			&__delete {
				height: calc(100% - 0.625rem);
				margin: 0 0.3125rem;
			}
			&__info {
				flex: 1;
				display: flex;
				justify-content: flex-end;
				align-items: center;
				overflow: hidden;
				&__name {
					flex: 1;
					width: 100%;
					height: 100%;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
				strong {
					flex: 0 0 5rem;
					white-space: nowrap;
					text-align: right;
				}
			}
		}
	}

	&--sm {
		@include inputDefaults(2rem);
	}
}
//-----FILE INPUT END-----

//-----REPORT DROPZONE-----
.v__dropzone {
	border-radius: $mini-radius;
	padding: 1rem;
	background: $bg-darker;
	z-index: 2;
	min-height: 10rem;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	border: none;

	&__label {
		flex: 0 0 100%;
		display: flex;
		align-items: center;
		justify-content: center;

		svg {
			width: 4.5rem;
			fill: $accent;
		}
	}

	&__files {
		flex: 0 0 100%;
		width: 100%;
		overflow: hidden;

		&__item {
			overflow: hidden;
			position: relative;

			&__delete {
				position: absolute;
				top: 0;
				right: 50%;
				transform: translateX(175%);
				padding: 0;
			}

			&__icon {
				width: 100%;
				text-align: center;

				svg {
					height: 5rem;
					margin: 0.625rem 0 1rem 0;
				}
			}

			&__info {
				display: flex;
				justify-content: center;
				align-items: center;
				white-space: nowrap;

				&__file-name {
					max-width: 30rem;
					@media (max-width: 800px) {
						max-width: 40vw;
					}
					min-width: 0;
					overflow: hidden;
					text-overflow: ellipsis;
					padding: 0 0.625rem 0 0;
				}

				strong {
					padding: 0 0 0 0.625rem;
				}
			}
		}
	}

	&:hover {
		cursor: pointer;
	}

	&:focus {
		outline: none;
		box-shadow: 0 0 0 1px $accent;
	}

	&__instructions {
		font-size: 0.9rem;
		text-align: center;
		@media (max-width: 800px) {
			font-size: 0.8rem;
		}
	}

	&__accepted {
		transition: $fast;
		flex: 0 0 100%;
		text-align: center;
		max-height: 0;
		color: $red;
		font-size: 0.9rem;
		margin: 0;
		overflow: hidden;
	}

	&--drag-accepted {
		box-shadow: 0 0 0 1px $green;

		.v__dropzone__label {
			svg {
				fill: $green;
			}
		}
	}

	&--drag-rejected {
		box-shadow: 0 0 0 1px $red;

		.v__dropzone__accepted {
			transition: $fast;
			max-height: 5rem;
			margin: 0.25rem 0.625rem 0 0.625rem;
		}

		.v__dropzone__label {
			svg {
				fill: $red;
			}
		}
	}

	&--populated {
		.v__dropzone__label {
			opacity: 0;
			pointer-events: none;
		}
	}

	&.disabled {
		.v__dropzone__label {
			opacity: 0;
			pointer-events: none;
		}
	}
}

.v__dropzone__file__status {
	position: absolute;
	top: 2rem;
	right: 1.3125rem;
}

.v__report__download__link {
	position: absolute;
	top: 3.5rem;
	left: 50%;
	transform: translate(-50%, 0);
}
//-----REPORT DROPZONE END-----

//-----IMAGE DROPZONE-----
.v__img__dropzone {
	border-radius: $mini-radius;
	padding: 1rem;
	background: $bg-darker;
	z-index: 2;
	min-height: 7.5rem;
	display: flex;
	justify-content: center;
	align-items: center;

	&:hover {
		cursor: pointer;
	}

	&:focus {
		outline: none;
		box-shadow: 0 0 0 1px $accent;
	}

	& > i {
		flex: 1;
		text-align: center;
		font-size: 1.5rem;
	}

	&__prev {
		flex: 1;
		text-align: center;
		&__img {
			width: 5rem;
			height: 5rem;
			border-radius: 50%;
			object-fit: cover;
			margin: 0 auto;
			font-size: 5rem;
			&[alt] {
				font-size: 0.9rem;
			}
		}
		&__instructions {
			margin-bottom: 0;
			font-size: 0.9rem;
		}
	}

	&__new {
		position: relative;
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		&__container {
			position: relative;
			& > button {
				position: absolute;
				top: 0;
				right: 0;
				transform: translate(25%, 0);
			}
		}

		&__img {
			width: 5rem;
			height: 5rem;
			border-radius: 50%;
			object-fit: cover;
			margin: 0 auto;
		}
	}

	&--valid {
		box-shadow: inset 0 0 0 1px $success;
	}

	&--invalid {
		box-shadow: inset 0 0 0 1px $danger;
	}

	@media (max-width: 800px) {
		min-height: 5rem;

		&__prev {
			&__img {
				width: 3rem;
				height: 3rem;
				font-size: 3rem;
			}
		}

		&__new {
			&__container {
				& > button {
					transform: translate(50%, -25%);
				}
			}

			&__img {
				width: 3rem;
				height: 3rem;
				font-size: 3rem;
			}
		}
	}
}

.invoice__manager__adjustments {
	background: $bg-darker;
	padding: 1rem 0.7rem;
	margin-bottom: 0rem;
}

.invoice__manager__adjustments p {
	font-family: "Roboto", sans-serif;
}

.dropzone__invalid__msg {
	color: $danger;
	font-size: 0.9rem;
	margin-top: 0.3125rem;
}
//-----IMAGE DROPZONE END-----

//-----SWITCH-----
.v__switch {
	@include switchDefaults(2rem, 4.5rem);
	&--sm {
		@include switchDefaults(1.25rem, 3rem);
	}
}
//-----SWITCH END-----

//-----SLIDER-----
.v__range__grid {
	.v__form__group {
		margin-bottom: 2rem;
	}
}

.v__slider {
	height: 2.375rem;
	padding: 0.4rem 0 1.475rem 0;
	left: 50%;
	transform: translate(-50%);
	width: calc(100% - 1.625rem);

	.rc-slider-rail {
		height: 0.5rem;
		background: $bg-darker;
	}

	.rc-slider-track {
		height: 0.5rem;
		background: $accent;
	}

	.rc-slider-step {
		height: 0.5rem;
	}

	.rc-slider-handle {
		width: 1rem;
		height: 1rem;
		margin-top: -0.25rem;
		border: none;
		background: $bg-card;
		box-shadow: 0 0 0 1px $accent;

		.v__slider__tooltip {
			position: absolute;
			bottom: -0.125rem;
			left: 50%;
			transform: translate(-50%, 100%);
			z-index: 9999;
			font-size: 0.8rem;
			font-weight: 500;
			user-select: none;
			background: $bg-card;
			padding: 0 0.125rem;
		}
	}
}
//-----SLIDER END-----

//-----SWITCH GROUP-----
.v__switch__group {
	margin: 0;
	display: flex;
	align-items: center;

	&__hint {
		display: flex;
		align-items: center;
		padding: 0 0.5rem;
		cursor: help;
		&:focus,
		&:focus-visible {
			outline: none;
		}
		i {
			font-size: 1.1rem;
			&:hover {
				color: $accent-darker;
			}
		}
	}

	&__container {
		@include switchGroupDefaults(2.375rem, $mini-radius, false);
	}

	&--dark {
		.v__switch__group__container {
			@include switchGroupDefaults(2.375rem, $mini-radius);
		}
	}

	&--sm {
		.v__switch__group__container {
			@include switchGroupDefaults(2rem, $mini-radius, false);
		}

		&.v__switch__group--dark {
			.v__switch__group__container {
				@include switchGroupDefaults(2rem, $mini-radius);
			}
		}
	}

	&--xs {
		.v__switch__group__container {
			@include switchGroupDefaults(1.625rem, $mini-radius, false);
		}

		&.v__switch__group--dark {
			.v__switch__group__container {
				@include switchGroupDefaults(1.625rem, $mini-radius);
			}
		}
	}
}
//-----SWITCH GROUP END-----

//-----COLOR PICKER-----
.v__color__picker {
	max-width: 35rem;
	width: 100%;
	min-width: 10rem;
	background: $bg-card;
	padding: 0;
	border-radius: $mini-radius;
	overflow: hidden;
	margin: 0 auto;
	box-shadow: $mini-shadow;
	z-index: 2;
	position: absolute;
	transform: translate(-50%, 0);
	&__wrapper {
		z-index: 1;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
		overflow-y: auto;
	}
	&__overlay {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		cursor: pointer;
	}
	&__current {
		height: 3rem;
		width: 100%;
		margin-top: 0.625rem;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	&__saturation {
		position: relative;
		width: 100%;
		height: 40vh;
		max-height: 30rem;
		margin-bottom: 0.625rem;

		&__pointer {
			width: 1rem;
			height: 1rem;
			background: transparent;
			box-shadow: inset 0 0 0 1px black, 0 0 0 1px white;
			border-radius: 50%;
			transform: translate(-50%, -50%);
		}
	}
	&__hue {
		position: relative;
		width: calc(100% - 4rem);
		margin: 0 2rem;
		height: 2rem;
		&__pointer {
			cursor: grab;
			width: 2rem;
			height: 2rem;
			background: white;
			border-radius: 50%;
			border: 1px solid $accent;
			transform: translateX(-50%);
			&:active {
				cursor: grabbing;
			}
		}
	}
	&--small {
		max-width: 15rem;
		.v__color__picker__saturation {
			height: 20vh;
			max-height: 10rem;
		}
		.v__color__picker__hue {
			width: calc(100% - 2rem);
			margin: 0 1rem;
			height: 1.5rem;
			&__pointer {
				width: 1rem;
				height: 2rem;
				border-radius: $radius;
				transform: translateX(-50%) translateY(-0.25rem);
			}
		}
	}
}
//-----COLOR PICKER END-----

//-----LIST SELECTION-----

.v__list-selection {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
	gap: 1.25rem;
	position: relative;

	@media (max-width: 800px) {
		grid-template-columns: 1fr;
	}

	.v__list-options {
		position: relative;
		border-radius: $mini-radius;
		box-shadow: 0 0 0 1px $dt-border;
		height: 40vh;
		@include staffPanels();
	}

	&__result {
		position: relative;
		background: $bg;
		border-radius: $mini-radius;
		box-shadow: 0 0 0 1px $dt-border;
		height: 40vh;
		overflow: hidden;
		overflow-y: auto;
		&__group {
			padding: 0.625rem 0.625rem 0 0.625rem;
			&:last-child {
				padding: 0.625rem;
			}
			&__title {
				background: $bg-card;
				padding: 0.625rem 1rem;
				border-radius: $mini-radius;
				cursor: pointer;
				display: flex;
				align-items: center;
				user-select: none;
				i {
					width: 1.75rem;
					text-align: left;
					font-size: 1.25rem;
				}
				span {
					color: $accent-darker;
					flex: 1;
				}
			}
			&__body {
				position: relative;
				&__item {
					margin-top: 0.25rem;
					padding: 0.3125rem 0.625rem;
					position: relative;
					display: flex;
					align-items: center;
					border-radius: $mini-radius;

					span {
						flex: 1;
					}

					&:hover {
						background: $bg-card;
					}
				}
			}
		}
	}
}
//-----LIST SELECTION END-----

//-----FORM GROUP-----
.v__form__group {
	position: relative;
	margin-bottom: 0.625rem;

	&--incorrect {
		.v__form__group__label {
			color: $red;
		}

		.v__input {
			box-shadow: 0 0 0 1px $red;
		}

		.v__select {
			.v__select__control {
				box-shadow: 0 0 0 1px $red;

				&--menu-is-open {
					box-shadow: 0 0 0 1px $red;
				}

				&--is-focused {
					box-shadow: 0 0 0 1px $red;
				}
			}
		}

		.v__form__group__hint {
			color: $red;
			font-size: 0.8rem;
		}

		.v__dropzone,
		.v__img__dropzone {
			box-shadow: 0 0 0 1px $red;
		}

		.v__list-selection {
			.v__list-options {
				box-shadow: 0 0 0 1px $red;
			}

			&__result {
				box-shadow: 0 0 0 1px $red;
			}
		}

		.v__file {
			box-shadow: 0 0 0 1px $red;
		}
	}

	&--no-overflow {
		margin-bottom: 0;
		padding-bottom: 0.625rem;
		overflow: hidden;
	}

	&__label {
		display: block;
		color: $secondary-font;
		font-size: 0.9rem;
		text-overflow: ellipsis;
		& > span {
			margin: 0 0 0 0.625rem;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		&--invisible {
			position: absolute;
			opacity: 0;
			pointer-events: none;
		}
		&--with-action {
			display: flex;
			align-items: center;
			& > span {
				flex: 1;
			}
			& > a {
				margin: 0 0.625rem;
				display: flex;
				align-items: center;
				font-size: 0.8rem;

				i {
					font-size: 1rem;
					margin: 0 0.25rem 0 0;
				}
				&:hover {
					color: $accent-darker;
				}
			}
		}
	}

	&__hint {
		margin: 0 0 0 0.625rem;
		color: $secondary-font;
	}

	.v__switch {
		margin-top: 0.1875rem;
	}
}
//-----FORM GROUP END-----

//-----FORM SEPARATED GROUP-----
.v__form__group__separated {
	position: relative;
	border-radius: $radius;
	box-shadow: 0 0 0 1px rgba($secondary-font, 0.1);
	padding: 1rem 0 0 0;
	margin-top: 1rem;

	&__label {
		position: absolute;
		top: -1rem;
		left: 1rem;
		cursor: help;
		span {
			user-select: none;
			font-size: 0.9rem;
			background: $bg-card;
			padding: 0 0.625rem;
			color: $secondary-font;
			& > i {
				vertical-align: sub;
				font-size: 1.15rem;
				padding-right: 0.3125rem;
			}
		}

		&__hint {
			@include tooltip();
			top: 2rem;
			p {
				font-size: 0.9rem;
				margin: 0;
			}
		}
	}
}
//-----FORM SEPARATED GROUP END-----

//-----FORM FOOTER-----
.v__form__footer {
	padding-top: 0.625rem;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	flex-wrap: wrap;
	button {
		margin-left: 0.625rem;
	}
}
//-----FORM FOOTER END-----

.v__form__error {
	background: $danger;
	padding: 0.625rem;
	border-radius: $mini-radius;
	text-align: center;
	font-size: 0.9rem;
	margin-bottom: 0.625rem;
	* {
		color: $danger-text;
	}
}

textarea {
	resize: vertical;
	&.v__input {
		@include inputDefaults(7rem);
		padding: 0.625rem;
		text-indent: 0;

		&--sm {
			@include inputDefaults(5rem);
		}
	}
}

input[type="checkbox"] {
	& + [data-checkable] {
		height: 1.25em;
		width: 1.25em;
		svg {
			stroke: rgba($secondary-font, 0.6);

			.box {
				stroke-dasharray: 320;
				stroke-dashoffset: 0;
			}

			.check,
			.indeterminate {
				stroke-dasharray: 70;
				stroke-dashoffset: 70;
			}
		}
	}

	&:checked + [data-checkable] {
		svg {
			stroke: $accent-darker;

			.check {
				stroke-dashoffset: 0;
			}
		}
	}

	&:indeterminate + [data-checkable] {
		.indeterminate {
			stroke-dashoffset: 0;
		}
	}
}
