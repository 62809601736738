html {
	@import "~rc-slider/assets/index.css";

	$font-family: "Poppins", "Roboto", sans-serif;

	* {
		font-family: $font-family;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		-moz-tap-highlight-color: rgba(0, 0, 0, 0);
	}

	*::-moz-focus-inner {
		border: 0 !important;
	}

	img {
		max-width: 100%;
		display: block;
	}

	a {
		text-decoration: none;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p {
		margin: 0 0 0.25rem 0;
	}

	$bronze: #cd7f32;
	$gold: #d4af37;

	$google: #e44034;
	$facebook: #4064ad;
	$linkedin: #0271ae;

	//TABS
	$tab-count: 0;

	//TRANSITIONS
	$very-fast: all 0.125s ease;
	$very-fast-s: 0.125s ease;
	$very-fast-sec: 0.125s;
	$fast: all 0.175s ease;
	$fast-s: 0.175s ease;
	$fast-sec: 0.175s;
	$mid: all 0.275s ease;
	$mid-s: 0.275s ease;
	$mid-sec: 0.275s;
	$elastic: all 0.35s cubic-bezier(0.68, -0.55, 0.265, 1.55);

	//BORDER-RADIUS
	$big-radius: 0.9375rem;
	$radius: 0.625rem;
	$mini-radius: 0.3125rem;

	body {
		&[data-theme="light"] {
			$red: #d53b49;
			$green: #218332;
			$orange: #f1b512;
			$blue: #3490dc;

			$sidebar-bg: #fefefe;
			$sidebar-bg-darker: #fefefe;
			$sidebar-primary-font: #141c23;
			$sidebar-secondary-font: #636377;

			$bg: #f6f7fb;
			$bg-darker: #f1f2f7;
			$bg-card: #ffffff;
			$bg-card-darker: #fefefe;

			$accent: #5a62b3;
			$accent-darker: #6060cc;
			//accent-lighter
			$accent-font: #fff;

			$success: #218332;
			$success-text: #fff;
			$danger: #d53b49;
			$danger-text: #fff;
			$warning: #f1b512;
			$warning-text: #201e22;

			$dt-row-bg: #f7f8fc;
			//dt-row-bg-darker
			$dt-row-hover: #f0f1f6;
			$dt-border: #eaebef;

			$primary-font: #141c23;
			$secondary-font: #1b252f;

			$logo-bg: #d53b49;
			$logo-accent: #5a62b3;

			$avatar-bg: #cacde9;
			$avatar-accent: #6b7c9d;

			$soft-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.075), 0 1px 5px 0 rgba(0, 0, 0, 0.1);
			$mini-shadow: 0 2px 10px -1px rgba(0, 0, 0, 0.1), 0 5px 10px -7px rgba(0, 0, 0, 0.1);
			//shadow
			$big-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.175), 0 0 30px 2px rgba(0, 0, 0, 0.1);

			background: $bg;

			@media print {
				color: black !important;
				background: white !important;
				padding-right: 0 !important;
			}

			@import "mixins";
			@import "main";
			@import "auth";
		}

		&[data-theme="dark"] {
			$red: #ff4d5e;
			$green: #218332;
			$orange: #f1b512;
			$blue: #2774ae;

			$sidebar-bg: #1b252f;
			$sidebar-bg-darker: #1b252f;
			$sidebar-primary-font: #e1e1eb;
			$sidebar-secondary-font: #9fadaf;

			$bg: #141c23;
			$bg-darker: #141c23;
			$bg-card: #1b252f;
			$bg-card-darker: #1b252f;

			$accent: #5369bc;
			$accent-darker: #829cff;
			//accent-lighter
			$accent-font: #fff;

			$success: #218332;
			$success-text: #fff;
			$danger: #d53b49;
			$danger-text: #fff;
			$warning: #f1b512;
			$warning-text: #201e22;

			$dt-row-bg: #172029;
			//dt-row-bg-darker
			$dt-row-hover: #2b3540;
			$dt-border: #253039;

			$primary-font: #dfefef;
			$secondary-font: #bfcfcf;

			$logo-bg: #dc3545;
			$logo-accent: #829cff;

			$avatar-bg: #10161c;
			$avatar-accent: #6b7c9d;

			$soft-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.075), 0 1px 5px 0 rgba(0, 0, 0, 0.1);
			$mini-shadow: 0 2px 10px -1px rgba(0, 0, 0, 0.1), 0 5px 10px -7px rgba(0, 0, 0, 0.1);
			//shadow
			$big-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.175), 0 0 30px 2px rgba(0, 0, 0, 0.1);

			background: $bg;

			@media print {
				color: black !important;
				background: white !important;
				padding-right: 0 !important;
			}

			@import "mixins";
			@import "main";
			@import "auth";
		}
	}
}

html,
body,
#root {
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	padding: 0;
	margin: 0;
}
