:root {
	--xxs: 320px;
	--xs: 480px;
	--sm: 640px;
	--md: 768px;
	--lg: 1024px;
	--xl: 1280px;
	--xxl: 1536px;
	--fhd: 1920px;

	--bronze: #cd7f32;
	--gold: #d4af37;

	--success: #218332;
	--danger: #d53b49;
	--warning: #f1b512;

	--accentDark: #5369bc;
	--accent: #5a62b3;
	--accentLight: #6060cc;
	--accentLighter: #829cff;

	--bgLight: #f6f7fb;
	--bgLightDarker: #f1f2f7;
	--bgLightCard: #ffffff;
	--bgLightCardDarker: #fefefe;

	--bgDark: #141c23;
	--bgDarkDarker: #141c23;
	--bgDarkCard: #1b252f;
	--bgDarkCardDarker: #1b252f;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes animatedBackground {
	0% {
		background-position: 0 0;
	}
	100% {
		background-position: 0px 300px;
	}
}

@keyframes santaAnimation {
	0% {
		opacity: 0;
		transform: translateX(0);
	}
	10% {
		opacity: 1;
		transform: translateX(10vw);
	}

	90% {
		opacity: 1;
		transform: translateX(80vw);
	}
	100% {
		opacity: 0;
		transform: translateX(90vw);
	}
}

.santa {
	animation: santaAnimation 20s forwards linear infinite;
}

/* radio-button default style */
.radio-button {
    padding: 0px;
    margin-right: 4rem;
	color: #fff;
	border: none;
	font-size: 0.9rem;
	font-family: "Poppins", "Roboto", sans-serif;
	font-weight: 400;
}
/* radio-button active style */
.radio-button.active {
	background-color: rgba(0, 0, 0, 0);
}
/* radio-button input default style */
.dot {
	width: 12px;
	height: 12px;
}
.dot::before {
	top: 2px;
	left: 2px;
	width: 8px;
	height: 8px;
}
/* radio-button input active style */
.dot.active {
}
.dot.active::before {
}
