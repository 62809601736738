//-----DEFAULTS-----
.v__btn {
	@include button(transparent, $primary-font);

	&.v__btn__sm {
		height: 2rem;
		i {
			font-size: 1rem;
		}
	}

	&.v__btn__xsm {
		height: 1.3rem;
		i {
			font-size: 1rem;
		}
	}

	&.v__btn__round {
		border-radius: 50%;
		width: 2.375rem;
		i {
			font-size: 1.4rem;
		}
		&.v__btn__sm {
			width: 2rem;
			i {
				font-size: 1rem;
			}
		}
	}

	&.v__btn__wide {
		width: 100%;
	}

	&.v__btn__space {
		i {
			padding-right: 0.5rem;
		}
		&__alt {
			i {
				padding-left: 0.5rem;
			}
		}
	}

	&.v__btn__spongy {
		@include spongy(0.975);
	}

	&.v__btn__left {
		justify-content: flex-start;
	}

	&__outlined {
		&--none {
			background: $bg;
			box-shadow: inset 0 0 0 2px $secondary-font;

			&:hover {
				box-shadow: inset 0 0 0 2px darken($secondary-font, 5%);
			}
		}

		&--accent {
			background: $bg;
			box-shadow: inset 0 0 0 2px $accent;

			&:hover {
				box-shadow: inset 0 0 0 2px darken($accent, 5%);
			}
		}

		&--danger {
			background: $bg;
			box-shadow: inset 0 0 0 2px $danger;

			&:hover {
				box-shadow: inset 0 0 0 2px darken($danger, 5%);
			}
		}

		&--success {
			background: $bg;
			box-shadow: inset 0 0 0 2px $success;

			&:hover {
				box-shadow: inset 0 0 0 2px darken($success, 5%);
			}
		}

		&--warning {
			background: $bg;
			box-shadow: inset 0 0 0 2px $warning;

			&:hover {
				box-shadow: inset 0 0 0 2px darken($warning, 5%);
			}
		}
	}

	&__bg {
		&--none {
			background: $bg-card;

			&:hover {
				background: darken($bg-card, 2.5%);
			}
		}

		&--darker {
			background: $bg-darker;

			&:hover {
				background: darken($bg-darker, 5%);
			}
		}

		&--accent {
			background: $accent;
			color: $accent-font;
			* {
				color: $accent-font;
			}

			&:hover {
				background: darken($accent, 5%);
			}
		}

		&--danger {
			background: $danger;
			color: $danger-text;
			* {
				color: $danger-text;
			}

			&:hover {
				background: darken($danger, 5%);
			}
		}

		&--success {
			background: $success;
			color: $success-text;
			* {
				color: $success-text;
			}

			&:hover {
				background: darken($success, 5%);
			}
		}

		&--warning {
			background: $warning;
			color: $warning-text;
			* {
				color: $warning-text;
			}

			&:hover {
				background: darken($warning, 5%);
			}
		}
	}

	&__hover {
		&--accent {
			&:hover {
				background: $accent;
				color: $accent-font;
				* {
					color: $accent-font;
				}
			}
		}
		&--danger {
			&:hover {
				background: $danger;
				color: $danger-text;
				* {
					color: $danger-text;
				}
			}
		}
		&--success {
			&:hover {
				background: $success;
				color: $success-text;
				* {
					color: $success-text;
				}
			}
		}
		&--warning {
			&:hover {
				background: $warning;
				color: $warning-text;
				* {
					color: $warning-text;
				}
			}
		}
	}

	&:disabled {
		pointer-events: none !important;
		opacity: 0.5 !important;
	}

	&.v__btn__overflow {
		overflow: visible;
	}

	.btn-indicator {
		position: absolute;
		top: 0;
		right: 0;
		transform: translate(0, -40%);
		font-size: 0.7rem;
		line-height: 1;
		white-space: nowrap;
		background: $danger;
		color: $danger-text;
		border-radius: $radius;
		padding: 0.125rem 0.3125rem;
	}
}
//-----DEFAULTS END-----

//-----CARD FILTER-----
.v__card__filter__btn {
	margin: 0 0 0 0.625rem;
	padding: 0 0.625rem;
	height: 1.625rem;

	&__indicator {
		transition: $fast;
		transform: rotateX(0deg);
	}

	span {
		padding: 0 0.625rem;
		font-weight: 600;
	}

	&--active {
		.v__card__filter__btn__indicator {
			transition: $fast;
			transform: rotateX(180deg);
		}
	}
}
//-----CARD FILTER END-----

//-----DASHBOARD BTN-----
.v__dashboard__report__btn {
	padding: 0 1rem;
	height: 3rem;
	font-size: 1.1rem;
	font-weight: 500;
	border: 2px dashed $accent;
	margin: 0 0 1rem 0;

	i {
		padding: 0 0.625rem;
		font-size: 1.35rem;
		color: $accent-darker;
	}

	&:hover,
	&:focus-visible {
		background: $accent;
		border: 2px solid transparent;
		color: $accent-font;

		i {
			color: $accent-font;
		}
	}

	@media (max-width: 1200px) {
		margin: 0 0 0.625rem 0;
	}
}
//-----DASHBOARD BTN END-----

.v__btn-group {
	position: relative;
	display: inline-flex;
	align-items: center;
	outline: none;

	& > * {
		border-radius: 0;
		&:focus,
		&:hover {
			z-index: 2;
		}
	}

	& > *:first-child {
		border-radius: $mini-radius 0 0 $mini-radius;
	}
	& > *:last-child {
		border-radius: 0 $mini-radius $mini-radius 0;
	}

	&--single {
		& > *:last-child {
			border-radius: $mini-radius;
		}
	}
}
