//-----CARD-----
.v__card {
	@include card();
	box-shadow: $mini-shadow;

	&--overflow-able {
		overflow: visible;
	}

	&__header {
		padding: 0 1rem;

		&__container {
			padding: 0;
			height: 3.625rem;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			flex-wrap: nowrap;
			position: relative;

			& > span {
				flex: 1;
				display: flex;
				align-items: center;
				font-size: 1rem;
				color: $primary-font;

				& > i {
					padding: 0 0 0 0.625rem;
					font-size: 1.15rem;
					cursor: help;

					&:hover {
						color: $accent-darker;
					}

					&:hover + .v__card__header__container__hint {
						opacity: 1;
					}
				}
			}

			&__inline-input {
				width: 12.5rem;
			}

			&__dismissible {
				width: 2rem;
				height: 2rem;
				padding: 0;
				margin: 0 0 0 auto;
			}

			.emoji {
				height: 1.1rem;
				margin: 0 0.25rem;
			}
		}
	}

	&__body {
		padding: 0 1rem 1rem 1rem;
		color: $primary-font;
		position: relative;
	}

	&__actions {
		display: flex;
		align-items: center;
		border-top: 1px solid $dt-border;
		margin: 0 0.625rem;
		padding: 0.625rem 0;
		&__title {
			flex: 1;
			font-size: 0.9rem;
			margin: 0 0.25rem;
		}
		&__items {
			display: flex;
			align-items: center;
		}
		&__item {
			width: 2rem;
			margin: 0 0.25rem;
		}
	}

	&.body-no-pad {
		.v__card__body {
			padding: 0;
		}
	}

	&.body-transparent {
		background: transparent;
		box-shadow: none;
	}

	&.body-no-mar {
		margin: 0;
	}

	@media (max-width: 800px) {
		&__header {
			padding: 0 0.625rem;
			&__container {
				height: unset;
				min-height: 3.625rem;
				padding: 0.625rem 0;
				& > span {
					flex-wrap: wrap;
					font-size: 0.9rem;
					line-height: 1.4;
				}
			}
		}

		&__body {
			padding: 0 0.625rem 0.625rem 0.625rem;
		}
	}
}

.v__card__filter__overlay {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 999999998;
	background: transparent;
}

.v__card__filter {
	position: absolute;
	z-index: 999999999;
	background: $bg-card;
	border: 1px solid rgba($secondary-font, 0.1);
	padding: 0.625rem 0.625rem 0 0.625rem;
	border-radius: $mini-radius;
	top: 3.625rem;
	right: 0;
	width: 100%;
	max-width: 20rem;
	@media (max-width: 800px) {
		max-width: 17.5rem;
	}

	&__title {
		padding: 0 0 0.625rem 0;
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
}

.v__tab__card {
	@include card();
	box-shadow: $mini-shadow;
	transition: $fast;
	&__header {
		position: relative;
		border-bottom: 1px solid rgba($secondary-font, 0.1);
		&__indicator {
			transition: $fast;
			will-change: left, width;
			position: absolute;
			bottom: 0;
			height: 0.25rem;
			background: $accent;
		}
		&__list {
			&__item {
				transition: $fast;
				color: $secondary-font;
				opacity: 0.6;
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;
				user-select: none;
				&__value {
					padding: 0.75rem 1.5rem;
					text-transform: capitalize;
				}
				&--active {
					transition: $fast;
					color: $primary-font;
					opacity: 1;
				}
				&:focus {
					outline: none;
				}
				&:focus-visible {
					background: rgba($bg, 0.5);
				}
			}
		}
	}
	&__body {
		transition: $mid;
		display: flex;
		align-items: flex-start;
		overflow: hidden;
	}
	&__panel {
		transition: $mid;
		flex: 0 0 100%;
		padding: 1rem;
	}
	// @media (max-width: 800px) {
	// 	&__header {
	// 		padding: 0 0.625rem;
	// 	}
	// }
}

.v__view__type-switch {
	position: relative;
	display: flex;
	align-items: flex-end;
	flex-wrap: wrap;
	margin: 0 0 0.625rem 0;
	overflow: visible;

	.v__switch__group {
		margin-right: 0.625rem;
	}

	& > .v__form__group {
		flex: 0 0 100%;
		width: 100%;
	}

	.v__view__report__time {
		margin: auto 0;
	}

	.v__view__report__actions {
		flex: auto;
		display: flex;
		margin-bottom: 0;
		margin-left: 0.625rem;
		.v__switch__group {
			margin-right: 0.625rem;
		}
		.v__form__group {
			margin-bottom: 0;
			margin-left: 0.625rem;
			min-width: 8rem;
		}
		& > *:first-child {
			margin-left: auto;
		}
	}

	@media (max-width: 1450px) {
		.v__switch__group {
			flex: 0 0 100%;
			margin-bottom: 0.625rem;
		}

		.v__view__report__time {
			position: absolute;
			transform: translateY(-20%);
		}

		.v__view__report__actions {
			flex: 0 0 100%;
			display: flex;
			margin-bottom: 0;
			margin-left: 0;
			.v__switch__group {
				flex: 0 0 auto;
				margin-bottom: 0;
			}

			& > *:first-child {
				margin-left: auto;
			}

			// & > *:last-child {
			// 	margin-left: auto;
			// }
		}
	}

	@media (max-width: 650px) {
		.v__view__report__actions {
			flex-wrap: wrap;
			.group_select {
				flex: 0 0 100%;
				margin-bottom: 0.625rem;
			}
			.v__switch__group {
				flex: 0 0 100%;
				margin-bottom: 0.625rem;
			}
		}
	}
}

.v__view__report__back {
	z-index: 2;
	position: absolute;
	right: 12.5rem;
}

@media (max-width: 1200px) {
	.v__view__report__time {
		position: absolute;
	}
	.v__view__report__back {
		position: relative;
		right: unset;
		margin: 0.625rem 0 0.625rem auto;
	}
}
@media (max-width: 800px) {
	.v__view__report__time {
		transform: translateY(25%);
	}
}

.v__view__performance__controls {
	position: relative;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	width: 100%;
	margin: 1rem 0;
	&__sort {
		margin: 0 auto 0 0;
		span {
			font-size: 1.1rem;
			display: flex;
			align-items: center;
		}
		i {
			font-size: 1.25rem;
			margin-left: 0.625rem;
			cursor: help;
			&:hover {
				color: $accent-darker;
			}
		}
		.v__card__header__container__hint {
			top: 2.75rem;
		}
	}
	&__nav {
		display: flex;
	}
	@media (max-width: 800px) {
		&__sort {
			flex: 0 0 100%;
			margin: 0 0 0.625rem 0;
		}
		&__nav {
			flex-wrap: wrap;
			width: 100%;
			margin: 0;
			.v__switch__group {
				flex: 0 0 100%;
				order: 0;
				margin-bottom: 0.5rem;
			}
			.v__performance__nav {
				order: 1;
				margin: 0 auto;
			}
		}
	}
}

.v__view__stats {
	padding-bottom: 1rem;
	&__banner {
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
		padding-bottom: 1rem;
		&__profile {
			flex: 0 0 50%;
			display: block;
			padding-right: 1rem;
			overflow: hidden;
			margin-bottom: -1rem;
			z-index: 2;
			&__info {
				display: flex;
				align-items: center;
				&__avatar {
					flex: 0 0 3.5rem;
					position: relative;
					height: 3.5rem;
					width: 3.5rem;
					background: $bg;
					border-radius: 50%;
					// .connectivity__indicator {
					// 	position: absolute;
					// 	right: 0.5rem;
					// 	top: 0.5rem;
					// 	width: 0.75rem;
					// 	height: 0.75rem;
					// 	z-index: 9;
					// }
					img {
						width: 100%;
						height: 100%;
						background: $bg;
						border-radius: 50%;
						object-fit: cover;
						z-index: 1;
						padding: 1px;
					}
					i {
						width: 100%;
						height: 100%;
						font-size: 3.5rem;
					}
					svg {
						width: 100%;
						height: 100%;
						padding: 0.625rem;
						&.depo__icon {
							padding: 0.8rem;
						}
						* {
							fill: $accent-darker;
						}
					}
					&::after {
						content: "";
						position: absolute;
						width: 100%;
						height: 100%;
						top: 0;
						left: 0;
						border-radius: 50%;
						background: transparent;
						box-shadow: inset 0 0 0 0.25rem $bg;
						z-index: 2;
					}
				}
				&__details {
					padding: 0 0 0 0.625rem;
					display: flex;
					align-items: center;
					justify-content: space-between;
					width: 100%;
					h2 {
						font-weight: 600;
						line-height: 1.2;
						p {
							margin: 0;
						}
						p:last-child {
							font-size: 80%;
						}
					}
					.v__form__group {
						max-width: 8rem;
						min-width: 8rem;
						margin-bottom: 0;
					}
				}
				@media (max-width: 500px) {
					align-items: flex-start;
					&__avatar {
						flex: 0 0 4rem;
						height: 4rem;
						width: 4rem;
						.connectivity__indicator {
							position: absolute;
							right: 0.3125rem;
							top: 0.3125rem;
							width: 0.625rem;
							height: 0.625rem;
							z-index: 9;
						}
						i {
							font-size: 4rem;
						}
						svg {
							padding: 0.5rem;
							&.depo__icon {
								padding: 1rem;
							}
						}
					}
					&__details {
						display: block;
						padding: 0 0.625rem;
						h2 {
							font-weight: 600;
							font-size: 1.1rem;
						}
					}
				}
			}
			&__ranking {
				transition: $fast;
				margin: 0.5rem 0;
				background: $bg;
				border-radius: $radius;
				padding: 1rem;
				position: relative;
				display: flex;
				align-items: center;
				overflow: hidden;
				user-select: none;
				&:hover {
					transition: $fast;
					box-shadow: $mini-shadow;
				}

				h1 {
					font-size: 1.75rem;
				}

				&__week {
					flex: 1;
					text-align: center;
					position: relative;
					i {
						transition: $fast;
						position: absolute;
						top: -1.25rem;
						left: -1rem;
						font-size: 9rem;
						pointer-events: none;
						user-select: none;
						opacity: 0.1;
						color: $secondary-font;
					}
					p {
						text-transform: uppercase;
						font-weight: 600;
						margin: 0;
					}
					small {
						color: $secondary-font;
					}
				}
				&__separator {
					flex: 0 0 0.125rem;
					height: 4rem;
					width: 0.125rem;
					border-radius: $mini-radius;
					background: rgba($secondary-font, 0.1);
				}
				&__month {
					padding: 0 1rem 0 2rem;
					text-align: left;
					position: relative;
					max-height: 5.5rem;
					overflow-y: auto;
					overscroll-behavior-y: contain;
					font-size: 0.9rem;
					p span {
						display: inline-block;
						width: 1.5rem;
					}
				}

				&__week:hover i,
				&__month:hover i {
					transition: $mid;
					color: $accent-darker;
					transform: scale(1.25);
				}
				@media (max-width: 500px) {
					&__week {
						i {
							top: 0;
							left: -2rem;
							font-size: 6rem;
						}
					}
					&__separator {
						height: 3rem;
					}
					&__month {
						i {
							top: 0;
							right: -2rem;
							font-size: 6rem;
						}
					}
				}
			}
			&__relationships {
				padding: 1rem 0 0.5rem 0;
				.staff__members {
					position: relative;
					padding: 1rem 1.25rem;
					box-shadow: inset 0 0 0 1px rgba($secondary-font, 0.1);
					border-radius: $radius;
					margin-bottom: 1rem;
					display: flex;
					align-items: center;
					&:last-child {
						margin-bottom: 0.5rem;
					}
					& > p {
						position: absolute;
						top: 0;
						left: 1rem;
						background: $bg-card;
						padding: 0 0.625rem;
						line-height: 1;
						transform: translateY(-50%);
					}
					img {
						transition: $fast;
						width: 2.5rem;
						height: 2.5rem;
						background: $bg;
						border-radius: 50%;
						object-fit: cover;
						padding: 1px;
						margin-left: -0.3125rem;
						cursor: pointer;
						&:first-child {
							margin-left: 0;
						}
						&:hover {
							transition: $fast;
							z-index: 2;
							box-shadow: 0 0 0 0.25rem rgba($secondary-font, 0.2);
						}
					}
					i {
						width: 2.5rem;
						height: 2.5rem;
						font-size: 2.5rem;
						background: $bg;
						margin-left: -0.3125rem;
						user-select: none;
						cursor: pointer;
						&:first-child {
							margin-left: 0;
						}
						&:hover {
							transition: $fast;
							z-index: 2;
							box-shadow: 0 0 0 0.25rem rgba($secondary-font, 0.2);
						}
					}
					&__left {
						cursor: pointer;
						margin-left: 1rem;
						&:hover {
							color: $accent-darker;
						}
					}
					@media (max-width: 400px) {
						img {
							width: 2rem;
							height: 2rem;
						}
						i {
							width: 2rem;
							height: 2rem;
							font-size: 2rem;
						}
					}
				}
				.user__connections {
					display: flex;
					align-items: flex-start;
					flex-wrap: wrap;
					margin: -0.5rem;
					padding-bottom: 0.5rem;
					@media (max-width: 1200px) {
						padding-bottom: 0;
					}
					& > div {
						flex: 1 0 auto;
						min-width: 12rem;
						padding: 0.5rem;
						@media (max-width: 1200px) {
							padding: 0.5rem 0.5rem 1rem 0.5rem;
						}
					}
					&__item {
						position: relative;
						padding: 1rem 1.25rem;
						box-shadow: inset 0 0 0 1px rgba($secondary-font, 0.1);
						border-radius: $radius;
						& > p {
							position: absolute;
							top: 0;
							left: 1rem;
							background: $bg-card;
							padding: 0 0.625rem;
							line-height: 1;
							transform: translateY(-50%);
						}
						a {
							font-size: 1rem;
							font-weight: 500;
							color: $accent-darker;
						}
					}
				}
			}
		}
		&__main {
			flex: 1;
			display: flex;
			flex-wrap: wrap;
			margin: -0.5rem;
			position: relative;
			&__loader {
				position: absolute;
				z-index: 10;
			}
			&__item {
				flex: 1 0 auto;
				padding: 0.5rem;
				& > div {
					transition: $fast;
					height: 100%;
					padding: 1rem;
					background: $bg;
					border-radius: $radius;
					text-align: center;
					position: relative;
					overflow: hidden;
					display: grid;
					&:hover {
						transition: $fast;
						box-shadow: $mini-shadow;
					}
				}
				&__title {
					display: flex;
					align-items: center;
					justify-content: center;
					font-weight: 700;
					font-size: 1.25rem;
					margin: 0;
					z-index: 1;
					color: $accent-darker;
					i {
						transition: $fast;
						pointer-events: none;
						user-select: none;
						font-size: 10rem;
						position: absolute;
						left: -3rem;
						top: -1rem;
						opacity: 0.1;
						z-index: 0;
					}
				}
				small {
					display: block;
					color: rgba($secondary-font, 0.7);
					padding: 0 0 0.5rem 0;
					z-index: 1;
				}
				&__value {
					font-weight: 700;
				}
				& > div:hover i {
					transition: $mid;
					transform: scale(1.25);
				}
			}
		}
		@media (max-width: 1200px) {
			&__profile {
				flex: 0 0 100%;
				padding-right: 0;
				margin-bottom: 0;
			}
		}
	}
	& > button {
		margin: 0 0 0 auto;
	}
	&__others {
		user-select: none;
		display: flex;
		flex-wrap: wrap;
		margin: -0.5rem;
		padding: 1rem 0 0 0;
		&__item {
			flex: 1 0 auto;
			padding: 0.5rem;
			&__title {
				display: flex;
				align-items: center;
				justify-content: center;
				font-weight: 500;
				font-size: 0.8rem;
				color: $secondary-font;
				i {
					font-size: 0.8rem;
					margin-right: 0.3125rem;
				}
			}
			small {
				transition: $mid;
				pointer-events: none;
				opacity: 0;
				position: absolute;
				top: 0;
				left: 50%;
				width: max-content;
				max-width: calc(100% + 1.8rem);
				transform: translate(-50%, 0);
				background: $bg-card;
				border-radius: $mini-radius;
				display: block;
				color: $secondary-font;
				box-shadow: $mini-shadow;
				border: 1px solid rgba($secondary-font, 0.1);
				padding: 0.625rem;
				&::after {
					content: "";
					width: 0px;
					height: 0px;
					position: absolute;
					border-left: 10px solid transparent;
					border-right: 10px solid transparent;
					border-top: 10px solid $bg-card;
					border-bottom: 10px solid transparent;
					left: 50%;
					bottom: -1rem;
					transform: translateX(-50%);
				}
			}
			&__value {
				font-weight: 700;
				margin: 0;
			}
			& > div {
				position: relative;
				padding: 1rem 2rem;
				background: $bg;
				border-radius: $radius;
				text-align: center;
				&:hover {
					z-index: 9999999;
				}
				&:hover > small {
					transition: $fast;
					will-change: opacity, transform;
					opacity: 1;
					transform: translate(-50%, -90%);
				}
			}
		}
	}
}

.staff__modal__container {
	height: 50vh;
	width: 100%;
	overflow: hidden;
	overflow-y: auto;
}

.staff__member__item {
	display: flex;
	align-items: center;
	padding: 0.625rem 1rem;
	border-radius: $mini-radius;
	background: lighten($bg, 2%);
	margin-bottom: 0.3125rem;
	overflow: hidden;
	&__container {
		padding: 0 0 0.3125rem 0;
	}
	& > img,
	& > i {
		width: 2.5rem;
		height: 2.5rem;
		font-size: 2.5rem;
		margin-right: 1rem;
		border-radius: 50%;
		object-fit: cover;
		@media (max-width: 800px) {
			width: 2rem;
			height: 2rem;
			font-size: 2rem;
			margin-right: 0.625rem;
		}
	}
	& > span {
		display: block;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}
	& > a {
		margin-left: auto;
	}
	&:hover {
		background: $bg;
	}
	&:last-child {
		margin-bottom: 0;
	}
}

//-----CARD END-----

//-----COLLAPSIBLE-----

.v__collapsible {
	@include card();

	&.no-mar {
		margin: 0;
	}

	&__header {
		cursor: pointer;
		padding: 0 1rem;
		@media (max-width: 800px) {
			padding: 0 0.625rem;
		}

		&__container {
			padding: 0;
			height: 3.625rem;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			flex-wrap: nowrap;
			position: relative;

			& > span {
				pointer-events: none;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				flex: 1;
				display: flex;
				align-items: center;
				font-size: 1rem;
				color: $primary-font;
			}

			& > i {
				font-size: 1.25rem;
				opacity: 0.8;
				pointer-events: none;
			}
		}
	}

	&__body {
		padding: 0.625rem 1rem 0.3125rem 1rem;
		@media (max-width: 800px) {
			padding: 0.625rem 0.625rem 0.3125rem 0.625rem;
		}
	}

	&[data-collapsed="true"] {
		transition: $mid;
		transition-delay: $mid-sec;
		margin: 0;

		.v__collapsible__header {
			&__container {
				border-color: transparent;

				& > i {
					transform: rotate(90deg);
				}
			}
		}
	}

	&[data-overflowable="true"] {
		overflow: visible;

		.v__collapsible__body {
			overflow: visible;
		}
	}

	&.body-no-pad {
		.v__collapsible__body {
			padding: 0;
		}
	}

	&--merged {
		margin: 0;
		box-shadow: inset 0 -1px 0 0 $bg;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		.v__collapsible__body {
			padding: 0.625rem 1rem 0 1rem;
			@media (max-width: 800px) {
				padding: 0.625rem 0.625rem 0 0.625rem;
			}
		}

		.v__collapsible[data-collapsed="true"] {
			&__header {
				&__container {
					border-color: transparent;

					& > i {
						transform: rotate(90deg);
					}
				}
			}
		}
	}

	&--blended {
		background: transparent;
		color: $secondary-font;
		box-shadow: none;
	}
}

//-----COLLAPSIBLE END-----

//-----MODAL-----

.v__modal__overlay {
	position: fixed;
	z-index: 99999999;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: hidden;
	overflow-y: auto;
	background: rgba($bg, 0.75);
	overscroll-behavior: contain;

	// @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
	//     backdrop-filter: blur(2px);
	// }

	&__inner {
		position: relative;
		width: 100%;
		min-height: 100%;
		padding: 3.5rem 0.625rem;

		.v__modal {
			max-width: 45rem;
			margin: 0 auto;
			box-shadow: $big-shadow;
			border: 1px solid rgba($secondary-font, 0.1);

			&--sm {
				max-width: 31.375rem;
			}

			&--lg {
				max-width: 103.9375rem;
				.v__quick-view__type-switch {
					display: flex;
					justify-content: flex-end;
					align-items: center;
					flex-wrap: wrap;
					margin: 0 0 0.625rem 0;
					overflow: visible;

					.v__switch__group {
						margin-right: auto;
						min-width: 15rem;
					}

					.v__form__group {
						margin-bottom: 0;
						min-width: 10rem;
					}

					@media (max-width: 800px) {
						.v__switch__group {
							flex: 0 0 100%;
							margin-right: unset;
							margin-bottom: 0.625rem;
						}

						.v__form__group {
							flex: 0 0 100%;
						}
					}
				}
				.v__table__container:last-child {
					margin: 0;
				}
			}
		}

		.v__modal__action {
			max-width: 60rem;
			margin: 0 auto;

			&__label {
				position: absolute;
				opacity: 0;
				pointer-events: none;
			}

			&__input {
				width: 100%;
				padding: 1rem 0;
				text-indent: 1rem;
				border: none;
				border-radius: 0;
				background: $bg-card;
				color: $primary-font;
				font-size: 1.5rem;
				box-shadow: 0 0 0 1px rgba($secondary-font, 0.1);

				&:focus {
					outline: none;
					box-shadow: 0 0 0 1px rgba($secondary-font, 0.1), inset 0 -3px 0 0 $accent;
				}

				&:-webkit-autofill,
				&:-webkit-autofill:hover,
				&:-webkit-autofill:focus {
					border: none;
					box-shadow: 0 0 0px 1000px $bg-card inset;
					-webkit-text-fill-color: $primary-font;
					-webkit-box-shadow: 0 0 0px 1000px $bg-card inset;
					transition: background-color 5000s ease-in-out 0s;
				}

				&:-webkit-autofill:focus {
					box-shadow: 0 0 0 1px rgba($secondary-font, 0.1), inset 0 -3px 0 0 $accent;
				}
			}
		}

		@media (max-width: 800px) {
			padding: 0;
		}
	}
}

//-----MODAL END-----

//-----INVITE MODAL HEADER-----
.v__user__card__info {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 0.625rem;

	&__avatar {
		flex: 0 0 5.25rem;
		padding: 0 0.625rem;
		display: flex;
		justify-content: center;
		align-items: center;

		& > img {
			width: 4rem;
			height: 4rem;
			border-radius: 50%;
			object-fit: cover;
			background: $bg;
		}

		& > svg {
			width: 4rem;
			height: 4rem;
		}
	}

	&__details {
		flex: 1;
		overflow: hidden;
		padding: 0 0.625rem;

		&__text {
			margin: 0 0 0.25rem 0;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;

			&.name {
				font-size: 1.15rem;
				font-weight: 600;
			}

			&.email {
				font-size: 0.9rem;
				color: rgba($secondary-font, 0.7);
			}
		}
	}

	@media (max-width: 800px) {
		&__avatar {
			flex: 0 0 4rem;
			padding: 0 0.25rem;

			& > img {
				width: 3.5rem;
				height: 3.5rem;
			}

			& > svg {
				width: 3.5rem;
				height: 3.5rem;
			}
		}
		&__details {
			padding: 0 0.5rem;
		}
	}
}

//-----INVITE MODAL HEADER END-----

//-----TICKET MODAL-----
.v__ticket__user {
	display: flex;
	align-items: center;
	padding: 0.625rem 0 1rem 0;
	&__avatar {
		height: 3rem;
		margin-right: 1rem;
	}
	&__details {
		line-height: 1;
		& > p {
			font-size: 1.1rem;
			margin: 0 0 0.5rem 0;
		}
		& > small {
			margin: 0;
			opacity: 0.75;
		}
	}
}

.v__ticket__list {
	border: 1px solid rgba($secondary-font, 0.1);
	border-radius: $mini-radius;
	padding: 0 0.25rem;
	height: 100%;
	max-height: 19.25rem;
	overflow: hidden;
	overflow-y: auto;
	&__title {
		font-size: 0.9rem;
		margin: 1rem 0 0 0.625rem;
	}
	&__item {
		transition: all 0.1s ease;
		display: flex;
		align-items: center;
		margin: 0.25rem 0;
		padding: 0 1rem;
		border-radius: $mini-radius;
		background: $bg-card;
		&:hover {
			transition: all 0.1s ease;
			background: $bg;
		}
		img {
			flex-shrink: 0;
			width: 3rem;
			height: 3rem;
			border-radius: $mini-radius;
			margin: 0.3125rem 1rem 0.3125rem -0.625rem;
			object-fit: cover;
			cursor: pointer;
		}
		.v__ticket__item__img__placeholder {
			flex-shrink: 0;
			width: 3rem;
			height: 3rem;
			border-radius: $mini-radius;
			margin: 0.3125rem 1rem 0.3125rem -0.625rem;
			background: $bg;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 2rem;
			color: rgba($secondary-font, 0.7);
		}
		p {
			line-height: 1.5;
			margin: 0;
			// white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			// width: clamp(10ch, calc(50vw - 3rem), 50ch);
			color: $secondary-font;
		}
		&__data {
			user-select: none;
			flex: 1;
			padding: 0.5rem 0;
			& > span {
				display: block;
				margin-bottom: 0.25rem;
			}
			&__status {
				display: flex;
				[class^="v__badge"] {
					margin-left: 0.625rem;
				}
			}
		}
	}
}
//-----TICKET MODAL END-----
